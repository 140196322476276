import { EnumTipoProduto, EnumTpProduto, MovSimplesProdutoModel } from "api/cardapio/models/produto/mov-simples-produto-model";
import { ProdutoCategoriaModel } from "api/cardapio/models/produto/produto-categoria-model";
import { ProdutoModel } from "api/cardapio/models/produto/produto-model";
import { isEmpty } from "lodash";
import { useCardapioApi } from "modulos/cardapio/containers/cardapio/hooks";
import { useCallback } from "react";
import { useAtualizarSacolaToda } from "storage/sacola/mutations/atualizar-sacola-toda";
import { CardapioStorageKeys, useCardapioStorage } from "storage/cardapio-storage";
import { useSearchURL } from "utils/get-id-url";
import { newGuid } from "utils/new-guid";
import { EnumRoundTo, roundTo } from 'utils/round-to';
import { toDecimal } from 'utils/to-decimal';

export const useMovProd = () => {

    const [atualizarSacolaToda] = useAtualizarSacolaToda()
    const { getRegistro } = useCardapioStorage()
    const { getIDFromUrl } = useSearchURL();
    const empresaId = getIDFromUrl('empresaId');
    const { dados: apiDados } = useCardapioApi(empresaId);

    const retornDescUsuario = useCallback((produto: MovSimplesProdutoModel) => {
        return produto.qCom === 0 ? 0 : produto.vFinal < produto.vDescUsuario ? 0 : produto.vDescUsuario
    }, []);

    const retornVFinal = useCallback((produto: MovSimplesProdutoModel) => {
        return produto.vFinal < 0 ? 0 : produto.vFinal
    }, []);

    const retornQCom = useCallback((produto: MovSimplesProdutoModel) => {
        return produto.tpProduto === EnumTpProduto.ProdutoComSubItem || produto.tpProduto === EnumTpProduto.Combo
            ? produto.qCom
            : produto.qCom > produto.infoSubItem!.qPadrao && !produto.idAdicional
                ? produto.infoSubItem!.qPadrao
                : produto.qCom
    }, []);

    const retornGroupId = useCallback((produto: MovSimplesProdutoModel, produtoPaiId: string) => {
        return produto.idGroup === null ? null : produtoPaiId
    }, [])

    const retornIndFin = useCallback((produto: MovSimplesProdutoModel, indFin: boolean) => {
        return indFin ? indFin : produto.produtoPaiId ? false : true;
    }, [])

    const adicionaProdutoComSubitens = useCallback(
        async (
            produto: MovSimplesProdutoModel,
            indFin: boolean = false,
            produtoPaiId: string,
            adicionaisGerais: MovSimplesProdutoModel[],
            tpProduto?: EnumTpProduto | EnumTipoProduto
        ) => {
            const { prodSubItem } = produto;
            let produtos: MovSimplesProdutoModel[] = []

            const product = {
                ...produto,
                vDescUsuario: retornDescUsuario(produto),
                vFinal: retornVFinal(produto),
                validacaoSubItem: true,
                indFin: retornIndFin(produto, indFin),
                idGroup: retornGroupId(produto, produtoPaiId),
                qCom: retornQCom(produto),
            };

            if ((product.tpProduto === EnumTpProduto.Adicional && product.qCom <= 0) || (product.tpProduto === EnumTpProduto.Opcional && product.qCom <= 0)) {
                return
            }

            const p = {
                ...product,
                indFin:
                    tpProduto === EnumTpProduto.Combo
                        && product.qCom > 0
                        ? true
                        : product.vUnCom === 0
                            ? false
                            : retornIndFin(produto, indFin),
                vProd: roundTo(
                    (product.qComModificador > 0
                        ? product.qComModificador
                        : product.qCom) * product.vUnCom,
                    3, EnumRoundTo.FINANCEIRO
                ),
                vFinal: roundTo(
                    product.vProd - product.vDescUsuario + product.vAcrescUsuario,
                    3, EnumRoundTo.FINANCEIRO
                ),
                qCom: toDecimal(product.qCom, 3),
                cobraTaxaServico: tpProduto === EnumTpProduto.Combo ? false : product.cobraTaxaServico
            }

            produtos.push(p);

            if (product && product.qCom > 0 && prodSubItem.length > 0) {
                for (let i of prodSubItem) {
                    const prod = {
                        ...i,
                        idDoProdutoPaiInfoSubItem: produto.id,
                        qCom: produto.qCom === 0 ? 0 : i.qCom,
                        vFinal: produto.qCom === 0 ? 0 : i.vFinal,
                        vDescUsuario: produto.qCom === 0 ? 0 : i.vDescUsuario,
                    };
                    const prodSub = await adicionaProdutoComSubitens(prod, false, produtoPaiId, adicionaisGerais, product.tpProduto);
                    if (prodSub) {
                        for (let subItem of prodSub) {
                            const ids = produtos.map(x => x.id)
                            if (!ids.includes(subItem.idGroup ?? '')) { 
                                produtos = [...produtos, ...prodSub]
                            } 
                        }
                    }
                }
            }

            return produtos;
        },
        [retornDescUsuario, retornGroupId, retornIndFin, retornQCom, retornVFinal]
    );

    const retornaModelMovSimples = useCallback(
        (
            produto: ProdutoModel
        ): MovSimplesProdutoModel => {
            const categoria = (apiDados.sessoes.data as ProdutoCategoriaModel[]).find(x => x.id === produto?.categoriaId)
            if (produto?.imagemUrl?.length! > 0) {
                try {
                    produto.imagemUrl = atob(produto?.imagemUrl!)
                } catch {
                }
            }

            const mov: MovSimplesProdutoModel = {
                ...new MovSimplesProdutoModel(),
                nSeq: 0,
                produtoGradeId: produto.produtoGradeId,
                tabelaPrecoId: '',
                vendedorId: '',
                vendedorNome: '',
                cProd: produto.codigo,
                cProdKit: '',
                cEan: '',
                xProd: produto.nome,
                ativo: true,
                uCom: produto.medida,
                qCom: 0,
                vUnCom: produto.vPreco,
                vProd: 0,
                vFrete: 0,
                vSeg: 0,
                vDescUsuario: 0,
                vAcrescUsuario: 0,
                vFinal: 0,
                imgUrl: produto.imagemUrl,
                categoria: categoria?.nome ?? '',
                infAdic: produto.infAdic,
                ncm: produto.ncm,
                temImposto: produto.temImposto,
                balanca: produto.balanca || 0,
                grupoImpostoId: produto.grupoImpostoId,
                ncmId: produto.ncmId,
                vTrib: 0,
                pTribManual: 0,
                pTribFederal: 0,
                pTribEstadual: 0,
                pTribMunicipal: 0,
                produtoId: produto.produtoId,
                id: produto.id,
                pedidoId: '',
                comandaId: '',
                mesaId: '',
                setorId: produto.setorId,
                salaoId: '',
                taxaServico: null,
                valorServico: null,
                cobraTaxaServico: produto.cobraTaxaServico,
                subItens: produto.subItens,
                contemSubItem: produto.contemSubItem,
                observacao: ""
            }

            mov.empresaId = empresaId

            return mov
        },
        [apiDados.sessoes.data, empresaId]
    );

    const insertCentralizadoProduto = useCallback(
        (
            produto: ProdutoModel,
            qCom: number,
            vUnCom: number,
            vProd: number,
            vDesc: number,
            vAcresc: number,
            observacao: string
        ) => {

            const model = retornaModelMovSimples(produto);
            model.id = newGuid();
            model.qCom = qCom;
            model.vUnCom = vUnCom !== 0 ? vUnCom : model.vUnCom;
            model.vProd = vUnCom === 0 ? vProd : model.vUnCom;
            model.vDescUsuario = vDesc;
            model.vAcrescUsuario = vAcresc;
            model.observacao = observacao;
            model.tpProduto = produto.tipo;

            return model
        },
        [retornaModelMovSimples]
    );

    const removerProdutoComSubItens = useCallback(async (
        produto: MovSimplesProdutoModel
    ): Promise<MovSimplesProdutoModel> => {

        const itensSacola = getRegistro(CardapioStorageKeys.Sacola, false)
        const sacola: MovSimplesProdutoModel[] = isEmpty(itensSacola) ? [] : itensSacola

        const ret = sacola.find((x) => x.nSeq === produto.nSeq);
        let sac = [...sacola]

        if (!ret) {
            throw new Error('O produto informado não foi localizado na venda.');
        }

        if (ret.produtoGradeId !== produto.produtoGradeId) {
            throw new Error(
                'Não é possível alterar o produto original da venda. Realize o cancelamento e faça o processo novamente',
            );
        }

        const filterProducts = sac.filter(p => p.id !== produto.id && p.idGroup !== produto.id);
        sac = filterProducts;
        await atualizarSacolaToda({ produtos: sac, empresaId: empresaId })
        return produto;
    },
        [atualizarSacolaToda, getRegistro, empresaId])

    const adicionarProdutoComSubItem = useCallback(async (
        produto: MovSimplesProdutoModel,
        adicionais: Array<MovSimplesProdutoModel>,
        valorFinal: number
    ) => {

        let produtos: MovSimplesProdutoModel[] = []
        const itensSacola = getRegistro(CardapioStorageKeys.Sacola, false)
        let sacola: MovSimplesProdutoModel[] = isEmpty(itensSacola) ? [] : itensSacola
        for (let i = 1; i <= produto.qCom; i++) {
            const newId = newGuid();

            const newAdicionais: MovSimplesProdutoModel[] = [];

            for (const add of adicionais) {
                const prod = {
                    ...add,
                    validacaoSubItem: true,
                    qCom: add.qCom,
                    idGroup: newId,
                    vDescUsuario: ((produto.tpProduto as unknown as EnumTipoProduto) === EnumTipoProduto.Produto ? EnumTpProduto.ProdutoComSubItem : EnumTpProduto.Combo) === EnumTpProduto.Combo ? add.vDescUsuario : 0,
                    tpProduto: EnumTpProduto.Adicional
                };
                newAdicionais.push(prod);
                const prodSub = await adicionaProdutoComSubitens(prod, true, newId, newAdicionais);
                if (prodSub) {
                    produtos = [...produtos, ...prodSub]
                }
            }
            const prod = { ...produto, adicionais: newAdicionais, qCom: 1, id: newId, tpProduto: (produto.tpProduto as unknown as EnumTipoProduto) === EnumTipoProduto.Produto ? EnumTpProduto.ProdutoComSubItem : EnumTpProduto.Combo };

            const prodSub = await adicionaProdutoComSubitens(
                prod,
                false,
                newId,
                newAdicionais,
                prod.tpProduto
            );
            if (prodSub) {
                produtos = [...produtos, ...prodSub]
            }


        }
        sacola = [...sacola, ...produtos.map((produto, index) => ({
            ...produto,
            nSeq: (sacola[sacola.length - 1]?.nSeq ?? 0) + (index + 1)
        }))]


        atualizarSacolaToda({ produtos: sacola, empresaId: empresaId })
    }, [adicionaProdutoComSubitens, atualizarSacolaToda, getRegistro, empresaId])

    const editarProdutoComSubItem = useCallback(async (
        produto: MovSimplesProdutoModel,
        adicionais: MovSimplesProdutoModel[],
        valorFinal: number
    ) => {
        // remove todos os produtos relacionados e depois adicionar o modificado
        await removerProdutoComSubItens(produto);
        let produtos: MovSimplesProdutoModel[] = []

        for (let i = 1; i <= produto.qCom; i++) {
            const newId = newGuid();
            const itensSacola = getRegistro(CardapioStorageKeys.Sacola, false)
            let sacola: MovSimplesProdutoModel[] = isEmpty(itensSacola) ? [] : itensSacola

            for (let j of adicionais) {
                const prodSub = await adicionaProdutoComSubitens({ ...j, validacaoSubItem: true, vDescUsuario: 0, qCom: j.qCom, idGroup: newId }, true, newId, adicionais);
                if (prodSub) {
                    produtos = [...produtos, ...prodSub]
                }
            }

            const prodSub = await adicionaProdutoComSubitens({ ...produto, adicionais: adicionais, qCom: 1, id: newId }, false, newId, adicionais);
            if (prodSub) {
                produtos = [...produtos, ...prodSub]
            }

            sacola = [...sacola, ...produtos.map((produto, index) => ({
                ...produto,
                nSeq: (sacola[sacola.length - 1]?.nSeq ?? 0) + (index + 1)
            }))]

            atualizarSacolaToda({ produtos: sacola, empresaId: empresaId })
        }
    }, [adicionaProdutoComSubitens, atualizarSacolaToda, empresaId, getRegistro, removerProdutoComSubItens])
    return {
        insertCentralizadoProduto,
        retornaModelMovSimples,
        adicionarProdutoComSubItem,
        editarProdutoComSubItem
    }
}