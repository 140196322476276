import { toString } from "lodash";

export function replaceAll(text: string, from: string, to: string): string {
    try {
        if (typeof text !== 'string') {
            text = toString(text);
        }
        let split = text.split('');
        split = split.map(char => {
            if (char === from) {
                return to;
            }
            return char;
        })
        return split.join('');
    } catch {
        try {
            return text.replaceAll(from, to)
        } catch {
            return text;
        }
    }
}