
import { create } from 'zustand';

export enum EnumDialogs {
    Autenticar,
    SelecionarMesa,
}


interface IDialogStore {
    dialogs: { codigo: EnumDialogs, aberto: boolean, props: any }[];
    openCloseDialog<T = any>(codigo: EnumDialogs, aberto?: boolean, props?: T): void;
}

/*
Criei esse store pra abrir dialogs, porém só estou usando em dialogs novos,
 não mexi ainda na lógica ainda com receio de quebrar algo
*/
export const useDialogStore = create<IDialogStore>()(
    (set, get) => ({
        dialogs: [
            {
                aberto: false,
                codigo: EnumDialogs.Autenticar,
                props: {},
            },
            {
                aberto: false,
                codigo: EnumDialogs.SelecionarMesa,
                props: {},
            }
        ],
        openCloseDialog(codigo, aberto, props) {
            const objeto = get();
            const dialog = objeto.dialogs.find(x => x.codigo === codigo);
            if (dialog) {
                const index = objeto.dialogs.indexOf(dialog);
                if (props) {
                    objeto.dialogs[index].props = props;
                }
                if (typeof aberto === 'boolean') {
                    objeto.dialogs[index].aberto = aberto;
                } else {
                    objeto.dialogs[index].aberto = !objeto.dialogs[index].aberto
                    return objeto;
                }
            }
            set((state) => ({
                ...state,
                dialogs: [...objeto.dialogs]
            }))
        },
    }),
);  