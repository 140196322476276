import React from 'react';
import { Grid, MenuItem, Paper, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { MesaModel, SalaoModel } from 'api/pedidos/models';
import * as Yup from 'yup';
import { EnumStatusMesa } from 'api/cardapio/models/enums/enum-status-mesa';

export const FormSelecionarMesaFormValidation = Yup.object().shape({
    mesaId: Yup.string().required('Informe uma Mesa'),
});


export interface FormSelecionarMesaProps {
    onSubmit: (mesaId: string) => any;
    listaSaloes: SalaoModel[];
    listaMesas: MesaModel[];
}

export interface FormSelecionarMesaRefs {
    submit: () => void;
    fillForm: (mesaId: string, salaoId: string) => void;
}

export const FormSelecionarMesa = React.forwardRef<
    FormSelecionarMesaRefs,
    FormSelecionarMesaProps
>(({ listaMesas, listaSaloes, onSubmit }: FormSelecionarMesaProps, ref) => {

    const {
        errors,
        handleSubmit,
        setFieldValue,
        values,
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            salaoId: '',
            mesaId: '',
        },
        validationSchema: FormSelecionarMesaFormValidation,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        onSubmit: () => { },
    });

    const triggerSubmit = () => {
        handleSubmit();
        if (Object.entries(errors).length > 0 || values.mesaId === '') {
            return { sucesso: false, data: undefined, erros: errors };
        }
        onSubmit(values.mesaId);
        return { sucesso: true, data: values, erros: undefined };
    };

    React.useImperativeHandle(ref, () => ({
        submit: triggerSubmit,
        fillForm(mesaId, salaoId) {
            setFieldValue('salaoId', salaoId);
            setFieldValue('mesaId', mesaId);
        },
    }));

    return (
        <Paper elevation={0} component="form" style={{ width: '100%' }}>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Salão"
                        value={values.salaoId}
                        onChange={(event) => {
                            if (event) {
                                setFieldValue('salaoId', event.target.value);
                            }
                        }}
                        defaultValue=""
                        error={Boolean(errors.salaoId)}

                    >
                        {listaSaloes.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                                {option?.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Mesa"
                        value={values.mesaId}
                        onChange={(event) => {
                            if (event) {
                                setFieldValue('mesaId', event.target.value);
                            }
                        }}
                        defaultValue=""
                        error={Boolean(errors.mesaId)}

                    >
                        {listaMesas.filter(x => {
                            if (x.status.codigo !== EnumStatusMesa.ATIVO) {
                                return false;
                            }
                            return values.salaoId ? x.salaoId === values.salaoId : true
                        }).map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                                {option?.codigo}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Paper>
    );
});