import React from 'react';
import { LoginRoute } from './componentes';
import { PullToRefreshContainer } from 'componentes/pull-to-refresh-container/pull-to-refresh-container';

export const CardapioRouter = () => {
  return (
    <PullToRefreshContainer>
      <LoginRoute />
    </PullToRefreshContainer>
  );
};
