import { makeStyles } from "@material-ui/core";
import Background from '../../../../../arquivos-estaticos/global/imagens/bg.png'

export const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '600px',
        zIndex: 99999,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        overflowX: 'hidden',
    },
    container: {
        padding: theme.spacing(0, 2, 2, 2),
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    drawerBg: {
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: '0.08',
        filter: theme.palette.type === "light" ? 'invert(1)' : 'none',
        zIndex: 0,
        position: 'absolute',
        backgroundSize: 'auto 100%',
        backgroundImage: `url('${Background}')`,
        backgroundPosition: 'center',
        backgroundRepeatX: 'repeat',
        backgroundRepeatY: 'no-repeat'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textoTotais: {
        fontWeight: 500,
    },
    buttonPedir: {
        backgroundColor: '#20B837',
        color: 'white',
        marginLeft: '1rem',
        [theme.breakpoints.down('sm')]: { marginLeft: 'unset' },
    },
    btnFooter: {
        marginTop: '1rem',
        flex: 1,
        [theme.breakpoints.down('sm')]: { width: '100%' },
    },
    grdBtns: {
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
    },
    scrollable: {
        position: 'relative',
        overflowY: 'auto',
        maxHeight: '700px',
        marginTop: '.5rem',
        [theme.breakpoints.down('sm')]: {},
        '&::-webkit-scrollbar': {
            width: '18px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            backgroundColor: 'transparent ',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,.15)',
            },
        },
    },
    scrollPage: {
        '&::-webkit-scrollbar': { width: '0px' },
    },
    sacolaVazia: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    imgSacolaVazia: {
        [theme.breakpoints.down('sm')]: { height: '200px' },
    },
    footer: {
        marginTop: 'auto',
    },
    carregando: {
        backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
    },
    mesa: {
        fontSize: '24px',
        fontWeight: 500
    }
}));
