import { BrandCardModel, ChargeAuthorizationModel, CheckoutResponseModel, CustomerModel, DataCardModel, EnumAcquireType, EnumInstallmentType, EnumPaymentType, TemporaryTokenModel, TransactionModel } from "api/checkout/models";
import { usePostChargeAuthorization } from "api/checkout/requests/post-charge-authorization";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

export interface AuthorizeChargeOnCardProps {
  cardToken: TemporaryTokenModel
  amount: number
  cliente: CustomerModel
  merchantToken: string
  merchantCredential: string
  acquirer?: EnumAcquireType
  installmentNumber?: number
  installmentType?: EnumInstallmentType
  softDescriptor?: string
}

export interface MerchantProps {
  merchantToken: string
  merchantCredential: string
}

export type SetCredentialsProps = undefined | MerchantProps

export const useCheckout = () => {
  // STATES AND REFS
  const [internalLoading, setInternalLoading] = useState<boolean>(false);

  // CALL API
  const {
    postChargeAuthorization,
    carregando: loadingChargeAuthorization,
  } = usePostChargeAuthorization();

  // AUX
  const loading = loadingChargeAuthorization || internalLoading;
  // const isDev = AmbienteEnv === 'devWeb';

  const checkoutKit = useCallback((): any => {
    return (window as any).SafraPayTransparent;
  }, []);

  const checkKit = useCallback(async (): Promise<boolean> => {
    return !isEmpty(checkoutKit());
  }, [checkoutKit]);

  const setCredentials = useCallback(
    (merchant: SetCredentialsProps) => {
      checkoutKit().setCredentials(merchant);
    },
    [checkoutKit]
  );

  const getBrand = async (cardNumber: string): Promise<BrandCardModel> => {
    return new Promise<BrandCardModel>(async (resolve, reject) => {
      try {
        setInternalLoading(true);
        if (!(await checkKit())) {
          return reject(
            new Error("Erro durante a comunicação com o serviço de pagamento.")
          );
        }
        const checkout = checkoutKit();
        await checkout.getCardBrand({
          bin: cardNumber.replaceAll(" ", "").substring(0, 6),
          success: (ev: BrandCardModel) =>
            setTimeout(() => {
              resolve(ev);
            }, 1000),
          error: (err: any) => {
            reject(err);
          },
        });
      } catch (e: any) {
        reject(e);
      } finally {
        setInternalLoading(false);
      }
    });
  };

  const createTemporaryCard = useCallback(
    async (e: BrandCardModel, cardData: DataCardModel) => {
      setInternalLoading(true);
      const expiry = cardData.expiry.replace(/\D/g, "");
      const date = new Date();
      const card = {
        brand: e.brand,
        number: cardData.number.replace(/ /g, ""),
        holderName: cardData.name.toUpperCase(),
        holderDocument: cardData.document.replace(/\D/g, ""),
        expirationMonth: expiry.substring(0, 2),
        expirationYear:
          date.getFullYear().toString().substring(0, 2) +
          expiry.substring(2, 4),
        cvv: cardData.cvc,
      };

      return new Promise<TemporaryTokenModel>(async (resolve, reject) => {
        try {
          if (!(await checkKit())) {
            return reject(
              new Error(
                "Erro durante a comunicação com o serviço de pagamento."
              )
            );
          }
          const checkout = checkoutKit();
          await checkout.createTemporaryCard({
            card,
            success: (e: TemporaryTokenModel) =>
              setTimeout(() => {
                resolve(e);
              }, 1000),
            error: (err: any) => {
              reject(err);
            },
          });
        } catch (e: any) {
          reject(e);
        } finally {
          setInternalLoading(false);
        }
      });
    },
    [checkKit, checkoutKit]
  );

  const authorizeChargeOnCard = useCallback(
    async ({
      acquirer = EnumAcquireType.Safrapay,
      amount,
      cardToken,
      cliente,
      merchantCredential,
      merchantToken,
      installmentNumber = 1,
      installmentType = EnumInstallmentType.None,
      softDescriptor,
    }: AuthorizeChargeOnCardProps): Promise<CheckoutResponseModel> => {
      try {
        setInternalLoading(true);
        const payload = new ChargeAuthorizationModel();
        payload.charge.transactions = [
          {
            ...new TransactionModel(),
            temporaryCardToken: cardToken.temporaryToken,
            paymentType: EnumPaymentType.Credito,
            amount: amount * 100,
            installmentNumber: installmentNumber,
            installmentType: installmentType,
            acquirer: acquirer,
          },
        ];

        payload.charge.Customer = cliente;
        payload.charge.gatewayId = "91001";

        const res = await postChargeAuthorization(
          payload,
          merchantCredential,
          merchantToken
        );
        if (res.erro) throw new Error(res.erro);

        const resultado = res.resultado?.data as CheckoutResponseModel

        return resultado;
      } finally {
        setInternalLoading(false)
      }
    },
    [postChargeAuthorization]
  );

  return {
    setCredentials,
    getBrand,
    createTemporaryCard,
    authorizeChargeOnCard,
    loading,
  };
};