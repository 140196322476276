import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { toDecimalStringGeneralizated } from 'utils/to-decimal';
import { useStyles } from './button-add-e-sub-styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty } from 'lodash';
import { EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { MovSimplesModificadoresProdutoModel } from 'api/cardapio/models/produto/mov-simples-modificadores-produto-model';

interface AddESubProps {
  model: MovSimplesProdutoModel;
  alterarQtdeProduto: (
    tpProduto: EnumTpProduto,
    valorProdutoPai: number,
    model: MovSimplesProdutoModel,
    qtde: number,
    modificador?: MovSimplesModificadoresProdutoModel,
    produtoGrade?: string,
    fator?: 'add' | 'sub'
  ) => void;
  qtd: number;
  setQtd: React.Dispatch<React.SetStateAction<number>>;
  verificar?: boolean;
  modificador?: MovSimplesModificadoresProdutoModel;
  edit?: boolean;
  qtdModificador?: number;
  valorProdutoPai: number;
  tpProdutoPai: EnumTpProduto;
  noZeroMin?: boolean;
  valorFracionado?: boolean;
}

export const AddESub = ({
  model,
  alterarQtdeProduto,
  qtd,
  setQtd,
  verificar,
  modificador,
  edit,
  qtdModificador,
  valorProdutoPai = 0,
  noZeroMin,
  tpProdutoPai,
  valorFracionado
}: AddESubProps) => {
  const classes = useStyles();
  const quantidadeModificador =
    modificador && modificador.qAtual <= modificador.qMin;

  const quantidadeMinima =
    noZeroMin ? model.qCom === 1 : (model.qCom === 0 ? model.qCom : qtd) <= (model?.infoSubItem?.qMin ?? 0);
  const produtoQtdZero = model.qCom === 0 || qtd === 0;

  const disabledButtonSub =
    produtoQtdZero ||
    quantidadeMinima ||
    quantidadeModificador ||
    !model.ativo ||
    verificar;

  const disabledButtonSubModificador =
    produtoQtdZero || !model.ativo || verificar;

  const valorDaQtd = (qCom: number) => {
    switch (qCom) {
      case 0:
        return 0;
      case 1:
        if (qtdModificador === 1) {
          return 1
        }
        return `${qCom}/${qtdModificador}`;
      default:
        if (qCom === qtdModificador) {
          return qCom
        }
        return `${qCom}/${qtdModificador}`
    }
  }

  return (
    <div className={classes.containerQtde}>
      {!verificar && model.ativo && isEmpty(model.cProdKit) && (
        <Button
          variant="contained"
          color="primary"
          disabled={modificador ? disabledButtonSubModificador : disabledButtonSub}
          onClick={() => {
            setQtd((prev) => {
              alterarQtdeProduto(tpProdutoPai, valorProdutoPai, model, prev - 1, modificador, '', 'sub');
              return prev - 1;
            });
          }}
          className={classes.btnAlterarQuantidade}
        >
          <RemoveIcon />
        </Button>
      )}
      <Grid className={classes.textQCom}>
        <Typography variant="body1" style={{ fontWeight: 600, fontSize: 18 }}>
          {(model.qComModificador > 0 &&
            model.qComModificador < 1 &&
            !edit &&
            verificar) || valorFracionado
            ? valorDaQtd(model.qCom)
            : toDecimalStringGeneralizated(
              model.qComModificador === 1 && !edit
                ? model.qCom === 0
                  ? model.qCom
                  : model.qComModificador
                : model.qCom,
              4
            )}
        </Typography>
        <Typography variant="caption">{model.uCom}</Typography>
      </Grid>
      {!verificar && model.ativo && isEmpty(model.cProdKit) && (
        <Button
          variant="contained"
          color="primary"
          disabled={
            qtd >= (model?.infoSubItem?.qMax ?? 100)
              ? true
              : false ||
              !model.ativo ||
              verificar ||
              (modificador && modificador.qAtual >= modificador.qMax) ||
              (model.qCom === 1 && qtdModificador === 1)
          }
          onClick={() => {
            setQtd((prev) => {
              alterarQtdeProduto(tpProdutoPai, valorProdutoPai, model, prev + 1, modificador, '', 'add');
              return prev + 1;
            });
          }}
          className={classes.btnAlterarQuantidade}
        >
          <AddIcon />
        </Button>
      )}
    </div>
  );
};
