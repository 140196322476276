import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterSacola, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { isEmpty } from 'lodash';
import { CardapioStorageKeys } from 'storage/cardapio-storage';


export interface AtualizarSacolaInput {
  produto: MovSimplesProdutoModel;
  empresaId: string;
  naoInvalidaCache?: boolean
}

class AtualizarSacola implements ApiMutation<AtualizarSacolaInput, MovSimplesProdutoModel> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ produto }: AtualizarSacolaInput) {

    let sacola = storageCardapio.load(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
    );

    const produtos = isEmpty(sacola) ? [] : sacola as MovSimplesProdutoModel[]

    const ret = produtos.find((x) => x.nSeq === produto.nSeq);
    
    if (ret) {
      if (ret.produtoGradeId !== produto.produtoGradeId) {
        throw new Error(
          'Não é possível alterar o produto original da venda. Realize o cancelamento e faça o processo novamente',
        );
      }
      var index = produtos.indexOf(ret);
      produtos[index] = produto;

      return storageCardapio
        .save(CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(), produtos)
    }


    if (produto.qCom < 0 && produto.vFinal < 0 && produto.vUnCom < 0) {
      throw new Error('Os valores do produto informado estão inválidos.');
    }
    produto.nSeq = (produtos[produtos.length - 1]?.nSeq ?? 0) + 1;
    produtos.push(produto);

    return storageCardapio
      .save(CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(), produtos)
  }

  updateCache(input: AtualizarSacolaInput, retorno: MovSimplesProdutoModel, cache: QueryCache) {
    const sessoesKey = obterSacola.getKey(input.empresaId);
    if (input.naoInvalidaCache) return
    return cache.invalidateQueries(sessoesKey);
  }
}

export const atualizarSacola = new AtualizarSacola();

export const useAtualizarSacola = (
  config?: MutationConfig<MovSimplesProdutoModel, unknown, AtualizarSacolaInput>

) => {

  const defaultConfig = useMutationConfig(
    {
      sucesso: '',
    },
    atualizarSacola,
  );

  return useMutation(atualizarSacola.execute, MergeDeep(defaultConfig, config));
};
