import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerCard: {
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        height: '100%'
    },
    stylesCard: {
        '& .rccs__name': {
            fontSize: 13
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3)
        }
    },
    contentCard: {
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            flex: 1
        },
        '&:first-child': {
            display: 'flex',
            alignItems: 'end'
        }
    },
}));