import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirectiom: 'column',
    },
    buttonDesvincular: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        '& svg': {
            fill: theme.palette.common.white,
        }
    }
}));