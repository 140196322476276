import { guidEmpty } from "utils/guid-empty";

export enum EnumTpPedidoPagamentoSituacao {
    Aprovado = 1,
    Cancelado = 2,
    Adiantado = 3
}

export enum EnumTipoPedido {
    ENTREGA = 0,
    RETIRAR = 1,
    LOCAL = 2,
}

export enum EnumPedidoPagamentoSituacao {
    APROVADO = 1,
    CANCELADO = 2,
    ADIANTADO = 3,
}

export enum EnumPagTpMod {
    DINHEIRO = 1,
    CHEQUE = 2,
    CARTAO_CREDITO = 3,
    CARTAO_DEBITO = 4,
    CREDITO_LOJA = 5,
    VALE_ALIMENTACAO = 10,
    VALE_REFEICAO = 11,
    VALE_PRESENTE = 12,
    VALE_COMBUSTIVEL = 13,
    DUPLICATA_MERCANTIL = 14,
    BOLETO_BANCARIO = 15,
    DEPOSITO_BANCARIO = 16,
    PAGAMENTO_INSTANTANEO = 17,
    TRANSFERENCIA_BANCARIA = 18,
    PROGRAMA_FIDELIDADE = 19,
    SEM_PAGAMENTO = 90,
    OUTRO = 99,
}

export class EfetuarPedidoModel {
    constructor(
        public comandaId?: string,
        public salaoId?: string,
        public mesaId?: string,
        public produtos: EfetuarPedidoProdutoModel[] = [],
        public cliente: EfetuarPedidoClienteModel = new EfetuarPedidoClienteModel(),
        public pags: EfetuarPedidoPagsModel[] = [],
        public finalizar: boolean = false,
        public enderecoEntrega: EfetuarPedidoEnderecoClienteModel | null = null,
        public tipoPedido: EnumTipoPedido = EnumTipoPedido.LOCAL,
        public pagamento: EfetuarPedidoPagamentoModel | null = null
    ) { }
}

export class EfetuarPedidoProdutoModel {
    constructor(
        public setorId: string = guidEmpty(),
        public salaoId: string = '',
        public indFin: boolean = true,
        public vendedor: string | null = null,
        public vendedorId: string | null = null,
        public produtoPai: string | null = null,
        public plu: string = '',
        public descricao: string = '',
        public observacao: string = '',
        public pesoVariavel: boolean = true,
        public codigoBarra: string = '',
        public tpProduto: number = 0,
        public status: number = 1,
        public indDesperdicio: number = 0,
        public nItem: number = 0,
        public posicaoMesa: string = '',
        public codigoReferencia: string = '',
        public quantidade: number = 0,
        public valorUnitario: number = 0,
        public valorTotal: number = 0,
        public taxaServico: number = 0,
        public valorTotalFrete: number = 0,
        public valorTotalServico: number = 0,
        public valorTotalSubItens: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalAdicional: number = 0,
        public subItens: EfetuarPedidoProdutoModel[] = [],
        public produtoIdReferencia: string = guidEmpty(),
        public produtoGradeId: string = guidEmpty(),
        public unidadeComercial: string = '',
    ) { }
}

export class EfetuarPedidoClienteModel {
    constructor(
        public referenceId: string | null = null,
        public nomeFantasia: string = '',
        public razaoSocial: string = '',
        public email: string = '',
        public cpfCnpj: string = '',
        public ieRg: string = '',
        public dataNascimento: string = '',
        public telefone: string = '',
    ) { }
}

export enum TipoStatusPagamentoEnum {
    EmAndamento = 0,
    Confirmado = 100,
    Estornado = 200,
    Ignorado = 900
}

export class EfetuarPedidoPagsModel {
    constructor(
        public status: TipoStatusPagamentoEnum = TipoStatusPagamentoEnum.Confirmado,
        public id?: string,
        public pagamentoId: string = "",
        public vPag: number = 0,
        public vTroco: number = 0,
        public concluido: boolean = true,
        public mensagem: string = '',
        public nroCartao: string | null = null,
        public nomeCartao: string | null = null,
        public bandeira: string | null = null,
        public tefId: string | null = null,
        public viaEstabelecimento: string | null = null,
        public viaConsumidor: string | null = null,
        public tpSolicitado: string | null = null,
        public nsu: string | null = null,
        public cAut: string | null = null,
    ) { }
}

export class EfetuarPedidoPagamentoModel {
    constructor(
        public id: string = guidEmpty(),
        public referenceId: string | null = null,
        public pagamentoDescricao: string = '',
        public valorPago: number = 0,
        public pagamentoPessoa: string = '',
        public tpMod: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
        public transacoes: string[] = [],
        public pagamentoAReceber: boolean = false,
        public valorTroco: number = 0,
        public situacao: EnumPedidoPagamentoSituacao = EnumPedidoPagamentoSituacao.APROVADO
    ) { }
}

export class EfetuarPedidoEnderecoClienteModel {
    constructor(
        public logradouro: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public uf: string = '',
        public cep: string = '',
        public complemento: string = '',
        public enderecoCompleto: string = '',
        public pontoReferencia: string = '',
        public codigoMunicipio: string = ''
    ) { }
}