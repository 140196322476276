import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  const colorSacola =
    theme.palette.type === 'light'
      ? theme.palette.primary.main
      : theme.palette.text.primary;
  return {
    root: {
      cursor: 'pointer',
      position: 'relative'
    },
    divider: {
      background: 'none',
      borderTop: `1px dashed ${colorSacola}`,
    },
    colorSacola: {
      color: colorSacola
    },
    icon: {
      fill: colorSacola
    }
  }
});