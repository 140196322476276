import { VariaveisAmbiente } from "ambiente";
import { EnumDeviceType } from "ambiente/ambiente-tipos";
import { useTokenUsuario } from "api/master/queries/token/obter-token-usuario";
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useMesaStore } from "storage/mesa-store";
import { timeStorage } from "utils";

export const useLoginEvent = () => {

    const { push } = useHistory();
    const { TokenValido } = useTokenUsuario();
    const desvincularMesa = useMesaStore(state => state.desvincularMesa);
    const empresaId = useMesaStore(state => state.empresaId);

    useEffect(() => {
        if (VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA)
            document.addEventListener('changeUrl', (event: any) => {
                let url = event.detail;
                const urlArr = url.split('/');
                const token = urlArr[4];
                const urlEmpresa = urlArr[2];

                if (token) {
                    if (TokenValido(token)) {
                        if (urlEmpresa !== empresaId) {
                            desvincularMesa();
                        }
                        timeStorage.save("tokenMasterUsuario", token, 3600)
                        localStorage.setItem("empresaMobile", urlArr[2]);
                        // localStorage.setItem(tokenUsuario, JSON.stringify(token))
                    }
                    push(`/${urlArr[2]}`);
                    return
                }
            })
    }, [TokenValido, desvincularMesa, empresaId, push])
}