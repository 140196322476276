import React, { useState, useEffect, useCallback, memo } from 'react';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Botao } from 'componentes';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RoomIcon from '@material-ui/icons/Room';
import { lojaAberta } from 'utils';
import { CardapioHeaderHorario } from '../cardapio-header-horario';
import { useStyle } from './cardapio-header-info-styles';
import { HorarioDiaModel } from 'api/cardapio/models/horario/horario-dia-model';
import classNames from 'classnames';
import { CardapioDadosProps } from '../../cardapio-header-props';

export interface CardapioHeaderInfoProps {
  empresa: CardapioDadosProps | undefined;
  idValido: boolean;
}

export const CardapioHeaderInfoComp = ({ empresa: dados, idValido }: CardapioHeaderInfoProps) => {
  const classes = useStyle({
    dados,
    idValido
  });
  const [modalHorarioAberto, setAberto] = useState(false);
  const [endereco, setEndereco] = useState('');
  const [segmento, setSegmento] = useState('');
  const [carregandoInfo, setCarregandoInfo] = useState(true);

  const [horarios, setHorarios] = useState<HorarioDiaModel[]>([]);

  const showHorarioFuncionamento = useCallback(() => {
    setAberto(true);
  }, []);

  const hideHorarioFuncionamento = useCallback(() => {
    setAberto(false);
  }, []);
  //controle do horário

  const empresa = dados?.empresa
  const statusLoja = lojaAberta(dados?.horarioFuncionamento!)

  const capitalize = useCallback((value: any) => {
    if (value === undefined)
      return 'ERRO AO PEGAR VALOR';
    let splitted = value.split(' ');
    let formatado = '';
    splitted.forEach((element: string) => {
      formatado = formatado.concat(' ' + element.charAt(0).toUpperCase() + element.slice(1));
    });
    return formatado;
  }, []);

  const enderecoFormatado = useCallback(() => {
    if (empresa?.logradouro === undefined)
      return "Logradouro não definido";

    if (empresa?.logradouro === null)
      return "Logradouro não definido";

    return `${capitalize(empresa?.logradouro?.toLowerCase())}, 
    ${capitalize(empresa?.numero?.toLowerCase())} -
    ${capitalize(empresa?.bairro?.toLowerCase())}, 
    ${capitalize(empresa?.municipio?.toLowerCase())}/${empresa?.uf}`;
  }, [
    capitalize,
    empresa?.bairro,
    empresa?.logradouro,
    empresa?.municipio,
    empresa?.numero,
    empresa?.uf,
  ]);

  //controle endereço
  useEffect(() => {
    if (empresa?.id && empresa) {
      setEndereco(enderecoFormatado());
      setSegmento(empresa?.segmento);
      setHorarios(dados?.horarioFuncionamento?.empresaHorarioDias ?? [])
      setCarregandoInfo(false);
    }
    else
      setCarregandoInfo(false);
  }, [dados?.horarioFuncionamento?.empresaHorarioDias, empresa, enderecoFormatado]);

  const redirectToMaps = useCallback(() => {
    window.open(`http://maps.google.com/maps?q=${enderecoFormatado().replace(' ', '%20')}`);
  }, [enderecoFormatado]);

  return (
    <div className={
      classNames(
        classes.root,
        idValido ? classes.centralizar : '',
        idValido && statusLoja
          ? classes.removeMargin
          : ''
      )
    }>
      {!idValido && (
        <div className={carregandoInfo ? classes.informacoesCarregando : classes.informacoes}>
          {carregandoInfo ? (
            <Skeleton />
          ) : (
            <Typography variant="body1" component="h2" gutterBottom>
              <div className={classes.textoInfo}>
                <div className={classes.iconInfo}>
                  <RestaurantIcon />
                </div>
                {segmento}
              </div>
            </Typography>
          )}
          {carregandoInfo ? (
            <Skeleton />
          ) : (
            <Typography
              className={classes.pointer}
              variant="body1"
              component="h2"
              onClick={redirectToMaps}
            >
              <div className={classes.textoInfo}>
                <div className={classes.iconInfo}>
                  <RoomIcon />
                </div>
                {endereco}
              </div>
            </Typography>
          )}
        </div>
      )}

      {statusLoja && idValido ? null : (
        <div className={classes.horarioContainer}>
          <Botao
            onClick={showHorarioFuncionamento}
            disabled={carregandoInfo}
            className={statusLoja ? classes.btnAberto : classes.btnFechado}
            size="medium"
            carregando={carregandoInfo}
            startIcon={!carregandoInfo ? <AccessTimeIcon /> : null}
          >
            {!carregandoInfo ? (statusLoja ? 'Aberto' : 'Fechado') : ''}
          </Botao>

          <CardapioHeaderHorario
            aberto={modalHorarioAberto}
            horarios={horarios}
            quandoForFechado={hideHorarioFuncionamento}
          />
        </div>
      )}
    </div>
  );
};
export const CardapioHeaderInfo = memo(CardapioHeaderInfoComp);
