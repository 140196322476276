import { PaymentCheckout } from "modulos/outros/checkout/components/payment-checkout"
import React, { useCallback, useMemo, useState } from "react"
import { useObterSacola } from "storage"
import { InformarCartaoProps } from "./informar-cartao-props"
import { PessoaModel } from "api/cardapio/models/pessoa/pessoa-model"
import { ResponsePaymentDataProps } from "modulos/outros/checkout/components/payment-checkout-props"
import { Box, Button, Fade, Grid, Typography } from "@material-ui/core"
import { FasesHeader } from "../../../components/fases-header/fases-header"
import { CartaoIcon } from "componentes/icons/cartao-icon"
import { Carregando, useNotificacoes } from "componentes"
import { useStyles } from "./informar-cartao-styles"
import { usePedidos } from "componentes/providers/pedidos-provider"
import { EfetuarPedidoPagsModel, TipoStatusPagamentoEnum } from "api/cardapio/models/pedido/efetuar-pedido"
import { CredenciamentoModel } from "api/cardapio/models/finalizadora/finalizadora-model"
import { EnumFasesSacola } from "modulos/cardapio/containers/cardapio/cardapio-sacola/cardapio-sacola"
import { EnumPaymentType } from "api/checkout/models"

export const InformarCartao = ({ empresaId, voltar, finalizadoraAtual, entrarFases, fecharSacola }: InformarCartaoProps) => {

    const { data } = useObterSacola(empresaId)
    const valor = (data ?? []).reduce((prev, curr) => prev + curr.vProd, 0)
    const [loading, setLoading] = useState<boolean>(false)
    const classes = useStyles()
    const { getCliente, handleCheckout, carregandoCriarPedido } = usePedidos()
    const { showSuccessMessage } = useNotificacoes()
    const carregando = carregandoCriarPedido || loading
    const [error, setError] = useState<string>()

    const getCredenciais = useMemo(() => {
        if (!finalizadoraAtual) return new CredenciamentoModel()
        const credenciais = JSON.parse(finalizadoraAtual.credenciais)
        return credenciais as CredenciamentoModel
    }, [finalizadoraAtual])

    const responsePayment = useCallback(async (data: ResponsePaymentDataProps) => {
        try {
            if (data.success) {
                if (data.data) {
                    const charge = data.data.charge
                    const transacao = charge.transactions[0]

                    if (charge.chargeStatus === 'NotAuthorized') {
                        throw new Error('Pagamento não autorizado. Verifique se o cartão tem limite disponível ou está desbloqueado para transações, e tente novamente para concluir seu pedido.')
                    }

                    const card = transacao.card

                    const payment: EfetuarPedidoPagsModel = {
                        ...new EfetuarPedidoPagsModel(),
                        nomeCartao: card.cardholderName,
                        bandeira: card.brandName,
                        concluido: true,
                        mensagem: 'Pagamento Crédito',
                        nroCartao: card.cardNumber,
                        pagamentoId: finalizadoraAtual?.id ?? '',
                        status: TipoStatusPagamentoEnum.Confirmado,
                        nsu: charge.nsu,
                        cAut: transacao.authorizationCode,
                        vPag: valor,
                        vTroco: 0,
                    }
                    await handleCheckout(payment)
                    showSuccessMessage('Pedido realizado com sucesso!')
                    entrarFases(EnumFasesSacola.Sacola)
                    fecharSacola()
                }
            }
        } catch (err: any) {
            setError(err.message)
        }
    }, [entrarFases, fecharSacola, finalizadoraAtual?.id, handleCheckout, showSuccessMessage, valor])

    const handleLoading = (value: boolean) => setLoading(value)

    return (
        <Fade in={true}>
            <Box display="flex" flexDirection="column" height="100%">
                <FasesHeader titulo="INFORMAR CARTÃO" icon={CartaoIcon} voltar={voltar} />
                {error ? (
                    <Grid container justify="center" alignItems="center" className={classes.container}>
                        <Box gridGap={16} display="flex" textAlign="center" flexDirection="column">
                            <Typography variant="h4">
                                <Box fontWeight={700}>
                                    Ocorreu um problema
                                </Box>
                            </Typography>
                            <Typography variant="body1">
                                {error}
                            </Typography>
                            <Button variant="outlined" onClick={() => setError(undefined)} size="large">
                                Tentar Novamente
                            </Button>
                        </Box>
                    </Grid>
                ) : (
                    <>
                        <Carregando
                            titulo={'Processando pagamento...'}
                            mensagem={"Finalizando o pagamento para concluir seu pedido. Só mais um momento."}
                            modelo="absolute"
                            carregando={carregando}
                        />
                        <Grid container justify="center" alignItems="center" className={classes.container}>
                            <PaymentCheckout
                                amount={valor}
                                cliente={getCliente() ?? new PessoaModel()}
                                merchantCredenciais={{
                                    merchantCredential: getCredenciais.cnpj || '',
                                    merchantToken: getCredenciais.merchantToken ?? '',
                                }}
                                paymentType={EnumPaymentType.Credito}
                                responsePayment={responsePayment}
                                setLoading={handleLoading}
                            />
                        </Grid>
                    </>
                )}
            </Box>
        </Fade>
    )
}