import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Router } from 'modulos/router';
import { ReactQueryDevtools } from 'react-query-devtools';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { VariaveisAmbiente } from 'ambiente';
import { CapturarErros, Notificacoes } from 'componentes';
import { EnumDeviceType } from 'ambiente/ambiente-tipos';

const queryCache = new QueryCache({
  defaultConfig: {
    mutations: {
      useErrorBoundary: false,
      throwOnError: false,
    },
    queries: {
      retry: 1,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
});

const SwitchRouter = ({ children }: any) => {
  const insertScriptCheckout = () => {
    const script = document.createElement('script');
    script.src =
      // AmbienteEnv === 'devWeb' ?
      // process.env.REACT_APP_CHECKOUT_URL_DEV! :
      process.env.REACT_APP_CHECKOUT_URL_PROD!;
    document.head.appendChild(script);
  };

  useEffect(() => {
    insertScriptCheckout();
  }, [])

  if (VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA) {
    return (
      <HashRouter>
        {children}
      </HashRouter>
    )
  }

  return (
    <BrowserRouter>
      {children}
    </BrowserRouter>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <SwitchRouter>
        <CapturarErros>
          <Notificacoes>
            {VariaveisAmbiente.isDev && <ReactQueryDevtools initialIsOpen={false} />}
            <Router />
          </Notificacoes>
        </CapturarErros>
      </SwitchRouter>
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
