import { makeStyles } from "@material-ui/core";
import { CardapioDadosProps } from "../../cardapio-header-props";

interface StyleProps {
    dados: CardapioDadosProps | undefined;
    idValido: boolean;
}

export const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: '1.2rem',
        display: 'flex',
        justifyContent: 'space-between',
        width: theme.typography.pxToRem(535),
        [theme.breakpoints.down('sm')]: {
            width: theme.typography.pxToRem(345),
            flexDirection: 'column-reverse',
        },
    },
    centralizar: {
        justifyContent: ({ idValido }: StyleProps) =>
            idValido
                ? 'flex-end'
                : 'center',
        width: '100%',
    },
    removeMargin: {
        marginTop: 0
    },
    horarioContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    },
    btnAberto: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    },
    btnFechado: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light,
    },
    informacoes: { width: 'unset' },
    informacoesCarregando: { width: '85%' },
    textoInfo: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500
    },
    pointer: {
        cursor: 'pointer',
    },
    iconInfo: {
        marginRight: '.5rem',
        color: theme.palette.primary.main
    },
    popper: {
        zIndex: 1,
    },
    item: {
        padding: 0,
    },
    destaque: {
        fontWeight: 'bold',
    },
    listItem: { display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' },
    listPrimary: { marginRight: '1rem', flex: 1 },
    listSecondary: { flex: 1 },
}));