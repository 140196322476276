import React, { useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { obterComanda, obterPedido } from 'api/pedidos/queries';
import { CardapioStorageKeys, useCardapioStorage } from 'storage/cardapio-storage';
import { EfetuarPedidoClienteModel, EfetuarPedidoEnderecoClienteModel, EfetuarPedidoModel, EfetuarPedidoPagamentoModel, EfetuarPedidoPagsModel, EfetuarPedidoProdutoModel, EnumPagTpMod, EnumPedidoPagamentoSituacao, EnumTipoPedido, TipoStatusPagamentoEnum } from 'api/cardapio/models/pedido/efetuar-pedido';
import { enviarDadosPedido } from 'api/pedidos/mutations';
import { DadosDelivery, EnumOpcoesDelivery, useAtualizarSacola, useEsvaziarSacola, useObterDadosDelivery, useObterSacola } from 'storage';
import { PedidoModel } from 'api/cardapio/models/pedido/pedido';
import { isEmpty } from 'lodash';
import { ComandaModel, MesaModel } from 'api/pedidos/models';
import { PessoaModel } from 'api/cardapio/models/pessoa/pessoa-model';
import { guidEmpty } from 'utils/guid-empty';
import { useGetLocalizacaoUsuario } from 'utils';
import { DistanciaModel } from 'api/master/models/distancia/distancia-model';
import { postDistancia } from 'api/master/mutations/distancia/enviar-distancia';
import { usePostMovRegistrar } from 'api/cardapio/queries/mov-pag/post-registrar';
import { MovRegistrarModel } from 'api/cardapio/models/mov-pag/mov-registrar';
import { FinalizadoraModel } from 'api/cardapio/models/finalizadora/finalizadora-model';
import { MovRegistrarResponseModel } from 'api/cardapio/models/mov-pag/mov-registrar-response';
import { stringNumeros } from 'utils/string-numeros';
import { CardapioDadosProps } from 'modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props';
import { roundTo } from 'utils/round-to';
import { calcPercent } from 'utils/calc-percent';
import { useGetPessoaById } from 'api/cardapio/queries/pessoa/get-pessoa-by-id';
import { EnumTipoProduto, EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { useNotificacoes } from 'componentes/notificacoes';
import { completarEndereco } from 'utils/completar-endereco';
import { getValueByConfig } from 'utils/get-config-by-cod';
import { EnumConfigEmpresa } from 'api/cardapio/models/enums/enum-config-empresa';
import { useMovProd } from 'hooks/mov-prod';
import { ProdutoModel } from 'api/cardapio/models/produto/produto-model';
import { usePostCalcularFrete } from 'api/cardapio/queries/geolocalizacao/calcular-frete';
import { CalcularFreteResultadoModel } from 'api/cardapio/models/geolocalizacao/calcular-frete-resultado-model';
import { useMesaStore } from 'storage/mesa-store';

interface CriarPedidoProps {
    isPedido?: boolean;
    finalizar?: boolean;
    transacao?: EfetuarPedidoPagsModel | null
    comandaCod?: string | null;
}

interface PedidosContextValue {
    pedidoAtual: PedidoModel | null;
    carregandoGetPedido: boolean;
    carregandoCriarPedido: boolean;
    carregandoEfetuarNovaVenda: boolean;
    carregandoRegistrarPix: boolean;
    carregandoHandlePedidoPix: boolean;
    codComanda: string | null;
    cliente: PessoaModel | null;
    getPedido: (comandaId?: string) => Promise<void>;
    criarPedido: (model: CriarPedidoProps) => Promise<void>;
    setCliente: (value: PessoaModel | null) => void;
    getCliente: () => null | PessoaModel;
    pegarComanda: (codigo: string) => Promise<ComandaModel | null | undefined>;
    setTransacao: (pagamento: EfetuarPedidoPagsModel | null) => void;
    handleRegistroPix: () => Promise<void>;
    dadosPix: MovRegistrarResponseModel | null;
    setDadosPix: (value: MovRegistrarResponseModel) => void;
    setCodComanda: (value: string | null) => void;
    handlePedidoPix: () => Promise<void>;
    handlePedido: () => Promise<void>;
    handlePedidoComanda: (codigo: string) => Promise<void>;
    cancelarPedido: () => void;
    handleCheckout: (transacao?: any) => Promise<void>;
    carregando: boolean
    addOuAttProdutoEntrega: (enderecoDelivery?: DadosDelivery) => Promise<boolean | undefined>
}

const PedidosContext = React.createContext<PedidosContextValue>({
    pedidoAtual: null,
    cliente: null,
    carregandoGetPedido: false,
    carregandoCriarPedido: false,
    carregandoEfetuarNovaVenda: false,
    carregandoRegistrarPix: false,
    carregandoHandlePedidoPix: false,
    codComanda: null,
    getPedido: (comandaId?: string) => new Promise(() => true),
    criarPedido: (model: CriarPedidoProps) => new Promise(() => true),
    setCliente: (value: PessoaModel | null) => { },
    getCliente: (): null | PessoaModel => null,
    pegarComanda: (codigo: string) => new Promise(() => null),
    setTransacao: (pagamento: EfetuarPedidoPagsModel | null) => { },
    handleRegistroPix: () => new Promise(() => { }),
    dadosPix: null,
    setDadosPix: (value: MovRegistrarResponseModel) => { },
    setCodComanda: (value: string | null) => { },
    handlePedidoPix: () => new Promise(() => null),
    handlePedido: () => new Promise(() => null),
    handlePedidoComanda: (codigo: string) => new Promise(() => null),
    cancelarPedido: () => null,
    handleCheckout: (transacao?: any) => new Promise(() => null),
    addOuAttProdutoEntrega: (enderecoDelivery?: DadosDelivery) => new Promise(() => true),
    carregando: false,
});

export const usePedidos = () => useContext(PedidosContext);

export interface PedidosProviderProps {
    children: React.ReactNode;
}

export enum EnumStatusProdutoPedido {
    DISPONIVEL = 1,
    INDISPONIVEL = 2,
    DESISTENCIA = 3,
    TROCADO = 4,
}

export const PedidosProvider = ({ children }: PedidosProviderProps) => {

    //HOOKS
    const [esvaziarSacola] = useEsvaziarSacola();
    const { showErrorMessage } = useNotificacoes();
    const { location } = useHistory();
    const mesaStore = useMesaStore(state => state.mesa)

    //STORAGE
    const { setRegistro, getRegistro } = useCardapioStorage()

    //STATES E REFS
    const [pedidoAtual, setPedidoAtual] = useState<PedidoModel | null>(null);
    const [carregandoGetPedido, setCarregandoGetPedido] = useState<boolean>(false)
    const [carregandoCriarPedido, setCarregandoCriarPedido] = useState<boolean>(false)
    const [cliente, setCliente] = useState<PessoaModel | null>(null)
    const [codComanda, setCodComanda] = useState<string | null>(null)
    const [, setTransacao] = useState<EfetuarPedidoPagsModel | null>(null)
    const [dadosPix, setDadosPix] = useState<MovRegistrarResponseModel | null>(null)
    const [carregandoDistancia, setCarregandoDistancia] = useState<boolean>(false)

    //HOOKS
    const { empresaId } = useParams<{ empresaId: string, mesaId: string }>();
    const { insertCentralizadoProduto } = useMovProd()
    const { postCalcularFrete, carregando: carregandoCalcularFrete } = usePostCalcularFrete()
    const [atualizarSacola] = useAtualizarSacola()
    const sacola = useObterSacola(empresaId)

    //CHAMADAS API
    const { obterLocalizacao } = useGetLocalizacaoUsuario();
    const { postMovRegistrar, carregando: carregandoMovRegistrar } = usePostMovRegistrar()
    const { getPessoaById, carregando: carregandoPessoaById } = useGetPessoaById()
    const getMesaId = useMesaStore(state => state.getMesaId)
    const mesaId = getMesaId(location.pathname);

    // AUX
    const dadosGerais = getRegistro(CardapioStorageKeys.DadosGerais, false) as CardapioDadosProps;
    const deliveryDados = useObterDadosDelivery({ empresaId: empresaId });
    const configEmpresa = dadosGerais.configuracoesEmpresa
    const configDelivery = dadosGerais.configuracoesDelivery
    const dadosEmpresa = dadosGerais.empresa
    const carregando = [carregandoCalcularFrete].includes(true)

    const deliveryHabilitado = (configDelivery?.deliveryHabilitado || false)

    const carregandoEfetuarNovaVenda = [
        carregandoPessoaById,
    ].includes(true)

    const getClientePadraoWrapper = useCallback(async () => {
        const clientePadraoId = getValueByConfig(EnumConfigEmpresa.ClientePadrao, configEmpresa)
        const clientePadraoStorage = getRegistro(CardapioStorageKeys.ClientePadrao) as PessoaModel

        if (!isEmpty(clientePadraoStorage) && clientePadraoStorage.id === (clientePadraoId?.valor ?? '')) {
            return clientePadraoStorage
        }
        const res = await getPessoaById(clientePadraoId?.valor ?? '', empresaId)
        if (res.erro) throw res.erro

        setRegistro(CardapioStorageKeys.ClientePadrao, res.resultado?.data)

        return res.resultado?.data as PessoaModel
    }, [configEmpresa, empresaId, getPessoaById, getRegistro, setRegistro])

    const carregandoRegistrarPix = [carregandoMovRegistrar, carregandoDistancia].includes(true)

    const carregandoHandlePedidoPix = [carregandoCriarPedido].includes(true)

    const pegarComanda = useCallback(async (codigo: string) => {
        const empresa = getRegistro(CardapioStorageKeys.DadosGerais, false) as CardapioDadosProps
        if (codigo.length > 3) {

            codigo = codigo.slice(codigo.length - 3, codigo.length)

            if (codigo[0] === '0' && codigo[1] === '0') {
                codigo = codigo.slice(codigo.length - 1, codigo.length)
            }
            else if (codigo[0] === '0') {
                codigo = codigo.slice(codigo.length - 2, codigo.length)
            }
        } else {
            if (codigo[0] === '0' && codigo[1] === '0') {
                codigo = codigo.slice(codigo.length - 1, codigo.length)
            }
            else if (codigo[0] === '0') {
                codigo = codigo.slice(codigo.length - 2, codigo.length)
            }
        }

        const ret = await obterComanda.execute(empresa.empresa.id, codigo);
        if (isEmpty(ret)) {
            throw new Error(`Verifique se é um código valido e tente novamente`);
        }
        const comandas = (ret ?? []) as ComandaModel[]

        return comandas.find(x => x.status.codigo === 1) ?? null

    }, [getRegistro])

    const getPedido = useCallback(async (codComanda?: string) => {
        setCarregandoGetPedido(true)
        setPedidoAtual(null)
        try {
            let res = null
            let comanda: ComandaModel | null | undefined = null
            if (codComanda) {
                comanda = await pegarComanda(codComanda)

                res = await obterPedido.execute({ empresaId: empresaId, comandaId: comanda?.id });
            } else {
                res = await obterPedido.execute({ empresaId: empresaId, mesaId: mesaId });
            }
            setPedidoAtual(res);
        }
        catch (err: any) {
            showErrorMessage(err.message);
        }
        finally {
            setCarregandoGetPedido(false)
        }
    }, [empresaId, mesaId, pegarComanda, showErrorMessage])

    const postDistanciaWrapper = useCallback(async (model: DistanciaModel) => {
        const res = await postDistancia.execute(model, empresaId);

        return res.dentro
    }, [empresaId])

    const prodEntregaParaProdutoModel = useCallback(() => {
        const prodEntrega = configDelivery?.produtoEntrega
        const prodEntregaGrade = prodEntrega?.grades?.[0]
        if (!prodEntrega) {
            return
        }

        const produtoModel: ProdutoModel = {
            ativo: prodEntregaGrade?.ativo ?? false,
            balanca: prodEntregaGrade?.balanca ?? 0,
            categoriaId: prodEntrega.categoriaId ?? '',
            cobraTaxaServico: false,
            codigo: prodEntregaGrade?.codigo ?? '',
            codigos: [],
            contemSubItem: false,
            empresaId: prodEntregaGrade?.empresaId ?? '',
            favorito: false,
            grupoImpostoId: '',
            id: prodEntrega?.id ?? '',
            idGroup: '',
            imagemUrl: '',
            infAdic: prodEntrega?.infAdic ?? '',
            medida: prodEntregaGrade?.medidaEntradaId ?? '',
            medidaDesc: 'UN',
            ncm: '',
            ncmId: prodEntrega?.ncmId ?? '',
            nome: prodEntrega?.nome ?? '',
            prodSubItem: [],
            produtoGradeId: prodEntregaGrade?.id ?? '',
            produtoId: prodEntrega?.id ?? '',
            pTribEstadual: 0,
            pTribFederal: 0,
            pTribManual: 0,
            pTribMunicipal: 0,
            setorId: prodEntrega?.setorId ?? '',
            subItens: [],
            temImposto: false,
            tipo: EnumTipoProduto.Produto,
            vPreco: prodEntregaGrade?.vPreco ?? 0,
            vTrib: 0
        }

        return insertCentralizadoProduto(produtoModel, 1, 0, 0, 0, 0, '')
    }, [configDelivery?.produtoEntrega, insertCentralizadoProduto])

    const addOuAttProdutoEntrega = async (enderecoModel?: DadosDelivery) => {
        const endereco = enderecoModel ?? deliveryDados?.data
        const res = await postCalcularFrete({
            bairro: endereco?.bairro ?? '',
            cep: endereco?.cep ?? '',
            cidade: endereco?.municipio ?? '',
            estado: endereco?.uf ?? '',
            logradouro: endereco?.lgr ?? '',
            numero: endereco?.nro ?? ''
        }, empresaId)
        if (res.erro) throw res.erro

        const resultado = res.resultado?.data as CalcularFreteResultadoModel

        if (!resultado.dentro) return false
        const prodEntrega = configDelivery?.produtoEntrega
        const itens = sacola.data
        const prodEntregaSacola = (itens ?? []).filter(x => x.produtoId === prodEntrega?.id)
        const valor = resultado.valorFrete

        if ((prodEntregaSacola?.length ?? 0) === 0) {
            const prod = prodEntregaParaProdutoModel()
            if (prod) {
                prod.vFinal = resultado.valorFrete
                prod.vProd = valor
                prod.vUnCom = valor
                prod.vFrete = valor
                await atualizarSacola({ produto: prod, empresaId })
            }
            return true
        }

        const prod = prodEntregaSacola[0]
        prod.vFinal = valor
        prod.vProd = valor
        prod.vUnCom = valor
        prod.vFrete = valor
        await atualizarSacola({ produto: prod, empresaId })
        return true
    }

    const pedidoProdutosWrapper = useCallback((
        produtosMovSimples: MovSimplesProdutoModel[],
        salaoId: string | null = null
    ) => {
        const taxaRecomendada = getValueByConfig(EnumConfigEmpresa.TaxaServicoRecomendada, configEmpresa)

        const produtoSerializados = produtosMovSimples.map((prod) => {
            return {
                setorEmpresaId: null,
                plu: prod.cProd,
                descricao: prod.xProd,
                observacao: prod.observacao,
                pesoVariavel: prod.balanca,
                codigoBarra: prod.cEan,
                status: prod.ativo
                    ? EnumStatusProdutoPedido.DISPONIVEL
                    : EnumStatusProdutoPedido.DESISTENCIA,
                nItem: prod.nSeq,
                posicaoMesa: '',
                codigoReferencia: prod.id,
                quantidade: prod.qCom,
                valorTotal: Math.round((prod.vFinal + Number.EPSILON) * 100) / 100,
                valorTotalDesconto: roundTo(prod.vDescUsuario),
                valorUnitario: roundTo(prod.vUnCom),
                valorTotalAdicional: 0,
                valorTotalFrete: prod.vFrete,
                vendedor: prod.vendedorNome,
                vendedorId: prod.vendedorId,
                setorId: prod.setorId,
                salaoId: salaoId,
                indFin: prod.tpProduto === EnumTpProduto.Combo ? false : prod.indFin,
                groupId: prod.idGroup,
                adicionalId: prod.idAdicional,
                subItens: pedidoProdutosWrapper(prod.prodSubItem, salaoId),
                produtoPai: prod.produtoPaiId,
                tpProduto: prod.tpProduto,
                taxaServico: prod.cobraTaxaServico && prod.indFin ? Number(taxaRecomendada?.valor ?? 0) : 0,
                valorTotalServico: prod.cobraTaxaServico && prod.indFin ? roundTo(calcPercent(prod?.vFinal ?? 0, Number(taxaRecomendada?.valor ?? 0))) : 0,
                produtoIdReferencia: prod.produtoId,
                quantidadeMax: prod.qCom,
                produtoGradeId: prod.produtoGradeId,
                unidadeComercial: prod.uCom,
            } as unknown;
        }) as EfetuarPedidoProdutoModel[];

        return produtoSerializados;
    }, [configEmpresa]);

    const criarPedido = useCallback(async ({
        finalizar = false,
        transacao = null,
        comandaCod = null
    }: CriarPedidoProps) => {
        setCarregandoCriarPedido(true)
        try {
            let comanda: ComandaModel | null | undefined = null
            const produtos = getRegistro(CardapioStorageKeys.Sacola, false) as MovSimplesProdutoModel[];

            const isComanda = comandaCod || codComanda

            const mesa = Object.values(getRegistro(CardapioStorageKeys.MesaAtual, false)).find(x => Boolean(x)) ?
                getRegistro(CardapioStorageKeys.MesaAtual, false) : mesaStore as MesaModel | null;

            const pedidos = getRegistro(CardapioStorageKeys.Pedidos, false) as any[]

            const produtosReq: EfetuarPedidoProdutoModel[] = pedidoProdutosWrapper(produtos, mesa?.salaoId)


            let request: EfetuarPedidoModel = new EfetuarPedidoModel();

            if (comandaCod || codComanda) {
                comanda = await pegarComanda(comandaCod! ?? codComanda)
            }
            if (mesa && !isEmpty(mesa) && mesaId) {
                request.salaoId = mesa.salaoId;
                request.mesaId = mesa.id;
            }

            if (comanda) {
                request.comandaId = comanda.id
            }

            if (!isComanda && !mesaId && deliveryHabilitado) {
                request.tipoPedido =
                    deliveryDados?.data?.tipo === EnumOpcoesDelivery.Entrega
                        ? EnumTipoPedido.ENTREGA
                        : EnumTipoPedido.RETIRAR
            }

            request.cliente = new EfetuarPedidoClienteModel();

            if (cliente && !isEmpty(cliente)) {
                request.cliente.razaoSocial = cliente.nome
                request.cliente.nomeFantasia = cliente.nome
                request.cliente.cpfCnpj = cliente.cpfcnpj
                request.cliente.referenceId = !cliente.id ? null : cliente.id
                request.cliente.telefone = cliente.contatos.find(x => x.tipo === 0)?.valor ?? ''
                request.cliente.email = cliente.contatos.find(x => x.tipo === 1)?.valor ?? ''
            } else {
                const clientePadrao = await getClientePadraoWrapper()
                request.cliente.razaoSocial = clientePadrao.nome
                request.cliente.nomeFantasia = clientePadrao.nome
                request.cliente.cpfCnpj = clientePadrao.cpfcnpj
                request.cliente.referenceId = !clientePadrao.id ? null : clientePadrao.id
                request.cliente.telefone = clientePadrao.contatos.find(x => x.tipo === 0)?.valor ?? ''
                request.cliente.email = clientePadrao.contatos.find(x => x.tipo === 1)?.valor ?? ''
            }

            if (!transacao && deliveryHabilitado && !mesaId) {
                request.pagamento = {
                    referenceId: guidEmpty(),
                    situacao: EnumPedidoPagamentoSituacao.APROVADO,
                    valorPago: produtosReq.reduce((a, b) => a + b.valorTotal, 0),
                    valorTroco: 0,
                    pagamentoDescricao: 'Levar Maquininha',
                    tpMod: EnumPagTpMod.DINHEIRO,
                    pagamentoAReceber: true,
                    pagamentoPessoa: request.cliente.razaoSocial,
                    transacoes: [],
                    id: guidEmpty()
                }
            }

            if (transacao) {
                request.pagamento = {
                    ...new EfetuarPedidoPagamentoModel(),
                    pagamentoAReceber: false,
                    pagamentoDescricao: transacao.mensagem,
                    pagamentoPessoa: request.cliente?.razaoSocial ?? '',
                    referenceId: transacao.pagamentoId,
                    situacao: EnumPedidoPagamentoSituacao.APROVADO,
                    tpMod: transacao.mensagem === 'PIX' ? EnumPagTpMod.PAGAMENTO_INSTANTANEO : EnumPagTpMod.DINHEIRO,
                    valorPago: transacao.vPag,
                    valorTroco: transacao.vTroco,
                }
                request.pags.push(transacao)
            }


            if (!mesaId && deliveryHabilitado && deliveryDados?.data) {
                if (deliveryDados.data.tipo === EnumOpcoesDelivery.Entrega) {
                    request.enderecoEntrega = {
                        ...new EfetuarPedidoEnderecoClienteModel(),
                        bairro: deliveryDados.data?.bairro ?? '',
                        cep: deliveryDados.data?.cep ?? '',
                        complemento: deliveryDados?.data.cpl ?? '',
                        logradouro: deliveryDados.data?.lgr ?? '',
                        municipio: deliveryDados.data?.municipio ?? '',
                        numero: deliveryDados.data?.nro ?? '',
                        pontoReferencia: deliveryDados.data?.referencia ?? '',
                        uf: deliveryDados.data?.uf ?? '',
                        enderecoCompleto: completarEndereco(deliveryDados.data.lgr, deliveryDados.data.nro, deliveryDados.data.municipio, deliveryDados.data.uf, deliveryDados.data.cep)
                    }
                } else {
                    request.enderecoEntrega = {
                        ...new EfetuarPedidoEnderecoClienteModel(),
                        bairro: dadosEmpresa?.bairro ?? '',
                        cep: dadosEmpresa?.cep ?? '',
                        complemento: dadosEmpresa?.complemento ?? '',
                        logradouro: dadosEmpresa?.logradouro ?? '',
                        municipio: dadosEmpresa?.municipio ?? '',
                        numero: dadosEmpresa?.numero || '0',
                        pontoReferencia: '',
                        uf: dadosEmpresa?.uf,
                        enderecoCompleto: completarEndereco(dadosEmpresa.logradouro, dadosEmpresa.numero, dadosEmpresa.municipio, dadosEmpresa.uf, dadosEmpresa.cep)
                    }
                }
            }

            request.finalizar = finalizar

            request.produtos = produtosReq;
            if (mesaId) {
                const localizacao = await obterLocalizacao();

                const resposta = await postDistanciaWrapper(localizacao);

                if (!resposta) {
                    setCarregandoDistancia(false)
                    throw new Error('Você saiu do raio definido pelo dono da loja. Volte para a loja ou cancele o pedido.')
                }
            }

            const resPedido = await enviarDadosPedido.execute(request, empresaId);

            setRegistro(
                CardapioStorageKeys.Pedidos,
                [
                    ...(!isEmpty(pedidos) ? pedidos : []),
                    resPedido
                ],
                false
            )

            esvaziarSacola({ empresaId: empresaId });
            setTransacao(null)
            setCodComanda(null)
            setCliente(null)
            setDadosPix(null)
            await getPedido();
        }
        finally {
            setCarregandoCriarPedido(false)
        }
    }, [cliente, codComanda, dadosEmpresa?.bairro, dadosEmpresa?.cep, dadosEmpresa?.complemento, dadosEmpresa?.logradouro, dadosEmpresa?.municipio, dadosEmpresa?.numero, dadosEmpresa?.uf, deliveryDados?.data, deliveryHabilitado, empresaId, esvaziarSacola, getClientePadraoWrapper, getPedido, getRegistro, mesaId, mesaStore, obterLocalizacao, pedidoProdutosWrapper, pegarComanda, postDistanciaWrapper, setRegistro]);

    const getCliente = useCallback(() => cliente, [cliente])

    const handleRegistroPix = useCallback(async (): Promise<void> => {
        const registrar: MovRegistrarModel = new MovRegistrarModel()
        const produtos = getRegistro(CardapioStorageKeys.Sacola, false) as MovSimplesProdutoModel[];

        setCarregandoDistancia(true)

        if (mesaId) {
            const localizacao = await obterLocalizacao();

            const resposta = await postDistanciaWrapper(localizacao);

            if (!resposta) {
                setCarregandoDistancia(false)
                throw new Error('Você saiu do raio definido pelo dono da loja. Volte para a loja ou cancele o pedido.')
            }
        }
        setCarregandoDistancia(false)

        const finalizadoras = dadosGerais.finalizadoras as FinalizadoraModel[]
        const pixFinalizador = finalizadoras.find(x => x.credenciais && x.credenciais.includes("\"tipo\":1"))

        if (finalizadoras.length === 0 || isEmpty(finalizadoras)) {
            throw new Error('Forma de pagamento não foi configurada corretamente ou não implementada.')
        }

        registrar.finalizadoraId = pixFinalizador?.id ?? guidEmpty()
        registrar.valor = produtos.reduce((a, b) => a + b.vFinal, 0)

        registrar.pagador = {
            cpfCnpj: cliente?.cpfcnpj ?? '',
            fone: stringNumeros(cliente?.contatos.find(x => x.tipo === 0)?.valor ?? ''),
            email: cliente?.contatos.find(x => x.tipo === 1)?.valor ?? '',
            nome: cliente?.nome ?? 'Consumidor'
        }

        const res = await postMovRegistrar(registrar, dadosGerais.empresa?.id)
        if (res.erro) throw res.erro

        setDadosPix(res.resultado?.data)
    }, [cliente?.contatos, cliente?.cpfcnpj, cliente?.nome, dadosGerais.empresa?.id, dadosGerais.finalizadoras, getRegistro, mesaId, obterLocalizacao, postDistanciaWrapper, postMovRegistrar])

    const handlePedidoPix = useCallback(async () => {
        const sacola = getRegistro(CardapioStorageKeys.Sacola, false) as MovSimplesProdutoModel[];

        const finalizadoras = dadosGerais.finalizadoras as FinalizadoraModel[]
        const pixFinalizador = finalizadoras.find(x => x.credenciais && x.credenciais.includes("\"tipo\":1"))
        await criarPedido({
            finalizar: true,
            transacao: {
                pagamentoId: pixFinalizador?.id ?? guidEmpty(),
                status: TipoStatusPagamentoEnum.Confirmado,
                vPag: sacola.reduce((a, b) => a + b.vFinal, 0),
                vTroco: 0,
                mensagem: 'PIX',
            } as EfetuarPedidoPagsModel
        })
    }, [criarPedido, dadosGerais.finalizadoras, getRegistro])

    const handlePedido = useCallback(async () => {
        await criarPedido({
            finalizar: false
        })
    }, [criarPedido])

    const handlePedidoComanda = useCallback(async (comanda: string) => {
        await criarPedido({
            finalizar: false,
            comandaCod: comanda
        })
    }, [criarPedido])

    const cancelarPedido = useCallback(() => {
        esvaziarSacola({ empresaId: empresaId });
        setTransacao(null)
        setCodComanda(null)
        setCliente(null)
        setDadosPix(null)
    }, [empresaId, esvaziarSacola])

    const handleCheckout = useCallback(async (transacao?: any) => {
        await criarPedido({ isPedido: false, finalizar: true, transacao })
    }, [criarPedido])

    return (
        <PedidosContext.Provider
            value={{
                // VARIÁVEIS
                pedidoAtual,
                carregandoCriarPedido,
                carregandoGetPedido,
                carregandoEfetuarNovaVenda,
                carregandoRegistrarPix,
                carregandoHandlePedidoPix,
                dadosPix,
                cliente,
                codComanda,
                carregando,

                // FUNÇÕES
                criarPedido,
                getPedido,
                setCliente,
                getCliente,
                pegarComanda,
                setTransacao,
                handleRegistroPix,
                setDadosPix,
                setCodComanda,
                handlePedidoPix,
                handlePedido,
                cancelarPedido,
                handleCheckout,
                handlePedidoComanda,
                addOuAttProdutoEntrega,
            }}>
            {children}
        </PedidosContext.Provider>
    );
};
