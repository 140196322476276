import React from 'react'
import { VariaveisAmbiente } from "ambiente"
import { EnumDeviceType } from "ambiente/ambiente-tipos"
import PullToRefresh from 'react-simple-pull-to-refresh';

export const PullToRefreshContainer = ({ children }) => {
    if (VariaveisAmbiente.deviceType !== EnumDeviceType.CORDOVA) {
        return <>
            {children}
        </>
    }

    return <PullToRefresh onRefresh={async () => {
        window.location.reload()
    }} pullingContent={<></>}>
        {children}
    </PullToRefresh>
}