import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    gridRoot: {
        minHeight: '100vh',
        paddingTop: '8rem',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '100px'
        }
    },
    olaImg: {
        width: '100%',
        margin: '0 auto',
    },
    cadastrar: {
        marginTop: '10px',
    },
    imgLogo: {
        height: '100px',
        margin: 'auto',
        display: 'flex',
        marginLeft: '.5rem',
    },
    bemvindo: {
        alignItems: 'flex-end',
        display: 'inline-flex',
    },
    buttonContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            margin: theme.spacing(1),
            '& button': {
                width: `calc(100% - 16px)`,
            }
        }
    }
}));