import { ApiQuery } from 'api/interfaces';
import { DadosDelivery, storageCardapio } from 'storage';
import { useQuery, QueryConfig } from 'react-query';
import { CardapioStorageKeys } from 'storage/cardapio-storage';
interface DadosDeliveryInput {
  empresaId: string;
}

class ObterDadosDelivery implements ApiQuery<DadosDeliveryInput, DadosDelivery | undefined> {
  getKey(params: DadosDeliveryInput): any {
    return ['ObterDadosDelivery', params];
  }

  execute(_: any, { empresaId }: DadosDeliveryInput): DadosDelivery | undefined {
    let itens = storageCardapio.load(
      CardapioStorageKeys[CardapioStorageKeys.DadosDelivery].toString(),
    ) as DadosDelivery[];
    if (Object.entries(itens).length === 0) {
      itens = [] as DadosDelivery[];
    }


    return itens.find((as) => as.idEmpresa === empresaId);
  }
}

export const obterDadosDelivery = new ObterDadosDelivery();

export const useObterDadosDelivery = (
  input: DadosDeliveryInput,
  config?: QueryConfig<DadosDelivery | undefined, DadosDeliveryInput>,
) => {
  return useQuery(obterDadosDelivery.getKey(input), obterDadosDelivery.execute, config);
};
