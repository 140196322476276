import React, { ChangeEvent, useCallback, useEffect, memo, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, InputBase, InputAdornment, Collapse } from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useStyles } from './cardapio-barra-pesquisa-styles';

export interface CardapioBarraPesquisaProps {
  pesquisa: boolean;
  termoPesquisa?: string;
  modo?: 'static' | 'dinamic';
  quandoPesquisar?: (event: ChangeEvent<HTMLInputElement>) => any;
  onPesquisaClick?: () => any;
  limparPesquisa?: () => any;
  fixo?: boolean
}

export const CardapioBarraPesquisa = ({
  pesquisa,
  modo,
  termoPesquisa,
  onPesquisaClick,
  quandoPesquisar,
  limparPesquisa,
  fixo
}: CardapioBarraPesquisaProps) => {

  // DEPENDENCIAS
  const classes = useStyles({ modo, fixo });

  // REFS
  const inputNumeroRef = useRef<HTMLDivElement | null>(null);

  const cancelSearch = useCallback(() => {
    if (limparPesquisa) limparPesquisa();
    if (onPesquisaClick && modo !== 'static') onPesquisaClick();
  }, [limparPesquisa, onPesquisaClick, modo]);

  useEffect(() => {
    if (pesquisa) {
      inputNumeroRef?.current?.focus();
    }
  }, [pesquisa]);

  return (
    <>
      <Collapse in={fixo || pesquisa} mountOnEnter unmountOnExit style={{
        width: fixo ? '500px' : "100%"
      }}>
        <div className={classes.searchBarContainer}>
          <InputBase
            inputRef={inputNumeroRef}
            className={classes.searchBar}
            value={termoPesquisa}
            onChange={quandoPesquisar}
            startAdornment={
              <InputAdornment position="start">
                <SearchRoundedIcon className={classes.searchIcon} />
              </InputAdornment>
            }
            classes={{
              input: classes.input,
              root: classes.inputRoot,
            }}
            placeholder="Buscar Produto"
          />
          {pesquisa && (
            <IconButton
              onClick={cancelSearch}
              aria-label="pesquisar"
              aria-haspopup="true"
              className={classes.cancelSearchIcon}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </Collapse>
    </>
  );
};
export const BarraPesquisa = memo(CardapioBarraPesquisa);
