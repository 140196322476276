import React from "react";
import { DefaultIconInternalProps } from "./default-icon-internal-props";
import { useDefaultIconStyles } from "./default-icon-styles";
import classNames from "classnames";

export const DefaultIcon = (props: DefaultIconInternalProps) => {
    const classes = useDefaultIconStyles();

    const myclass =
        props.tipo === "BUTTON" ? classes.defaultIconOnButton :
            props.tipo === "BUTTON_FAB" ? classes.defaultIconOnButtonFab :
                props.tipo === "BUTTON_PRIMARY" ? classes.defaultIconOnButtonPrimary :
                    props.tipo === "BUTTON_MEU_PEDIDO" ? classes.defaultIconOnButtonMeuPedido :
                        props.tipo === "INPUT" ? classes.defaultIconOnInput :
                            props.tipo === "PRIVATE_HEADER" ? classes.defaultIconOnPrivateHeader :
                                props.tipo === "MODAL_HEADER" ? classes.defaultIconOnModalHeader :
                                    ''


    return (
        <>
            <svg className={classNames(myclass, props.class)} onClick={props.onClick} style={
                {
                    fill: props.fill,
                    ...props.style
                }
            }
                viewBox="0 0 50 50">
                {props.children}
            </svg >
        </>
    );
};
