import { ApiQuery } from 'api/interfaces';
import { storageCardapio } from 'storage'
import { useQuery, QueryConfig } from 'react-query';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { CardapioStorageKeys } from 'storage/cardapio-storage';

type ObterSacolaProps = string

class ObterSacola implements ApiQuery<ObterSacolaProps, MovSimplesProdutoModel[]> {
  getKey(params: ObterSacolaProps) {
    return ['ObterSacola', params];
  }

  execute(_: any): MovSimplesProdutoModel[] {

    let itens = storageCardapio.load(CardapioStorageKeys[CardapioStorageKeys.Sacola].toString()) as MovSimplesProdutoModel[];
    if (Object.entries(itens).length === 0) {
      itens = [] as MovSimplesProdutoModel[];
    }
    return itens;
  }
}

export const obterSacola = new ObterSacola();

export const useObterSacola = (
  input: ObterSacolaProps,
  config?: QueryConfig<MovSimplesProdutoModel[], ObterSacolaProps>,
) => {
  return useQuery(obterSacola.getKey(input), obterSacola.execute, config);
};
