import React, { useCallback, useRef, memo } from 'react';
import { Fade } from '@material-ui/core';
import { isEmpty } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import { ImagemBlobParaBase64 } from 'utils';
import { BotaoTransparente, useNotificacoes } from 'componentes';
import EditIcon from '@material-ui/icons/Edit';
import { useStyles } from './cardapio-header-logo-styles';
import { useMasterApi } from '../../../hooks';
import { atualizarTemaLogo } from 'api/cardapio/mutations/tema/put-tema-logo';

export interface CardapioHeaderLogoProps {
  empresaId: string,
  logoUrl?: string;
  className?: string;
  somenteLeitura: boolean;
  onLogoChange?: (props: { logo: string }) => any;
}

const CardapioHeaderLogoComp = ({
  empresaId,
  logoUrl,
  className,
  somenteLeitura,
  onLogoChange,
}: CardapioHeaderLogoProps) => {
  const classes = useStyles();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const existeLogo = Boolean(logoUrl);
  const { showErrorMessage, showSuccessMessage } = useNotificacoes();

  const { operacoes } = useMasterApi(empresaId);
  const salvarLogo = useCallback(async (logo: string) => {
    try {
      await atualizarTemaLogo.execute({ empresaId, imagem: { imagemBase64: logo } })
      showSuccessMessage('Logo atualizada com sucesso.');
      operacoes.atualizarCadastroEmpresa.executar()
      if (onLogoChange) {
        onLogoChange({ logo });
      }
    }
    catch (error: any) {
      showErrorMessage('Erro ao tentar atualizar o logo. Verifique a sua conexão e tente novamente. Detalhe: ' + error.message)
    }
  }, [empresaId, onLogoChange, operacoes.atualizarCadastroEmpresa, showErrorMessage, showSuccessMessage])

  const onImportarClick = useCallback(async () => {
    inputFileRef?.current?.click();
  }, []);

  const onLogoCarregado = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isEmpty(event?.target?.files)) {
        return;
      }

      const file = event.target.files![0];
      const logo = await ImagemBlobParaBase64(file);

      await salvarLogo(logo);
    },
    [salvarLogo],
  );

  const exibirIconButton = existeLogo === true && somenteLeitura === false;
  const exibirButton = existeLogo === false && somenteLeitura === false;

  if (existeLogo === false && somenteLeitura) {
    return null;
  }

  return (
    <div className={classNames(className, classes.root)}>
      <input
        ref={inputFileRef}
        multiple={false}
        type="file"
        accept="image/*"
        onChange={onLogoCarregado}
        value={''}
        hidden
      />
      {existeLogo && (
        <Fade in={true} mountOnEnter unmountOnExit>
          <img src={logoUrl} className={classes.logo} alt="" />
        </Fade>
      )}
      <Fade in={exibirIconButton} mountOnEnter unmountOnExit>
        <BotaoTransparente
          tipo="iconButton"
          onClick={onImportarClick}
          className={classes.iconButton}
        >
          {existeLogo && <EditIcon fontSize="small" />}
          {!existeLogo && <AddIcon fontSize="small" />}
        </BotaoTransparente>
      </Fade>
      <Fade in={exibirButton} mountOnEnter unmountOnExit>
        <BotaoTransparente tipo="button" onClick={onImportarClick} size="small">
          <AddIcon className={classes.icon} fontSize="small" />
          Adicionar Logotipo
        </BotaoTransparente>
      </Fade>
    </div>
  );
};
export const CardapioHeaderLogo = memo(CardapioHeaderLogoComp);
