export enum EnumDeviceType{
  NAVIGATOR,
  CORDOVA,
}

export interface IAmbiente {
    cardapioApi: string;
    masterApi: string;
    pedidosApi: string;
    authFront: string;
    appName?: string;
    touchoneApi?: string;
    deviceType: EnumDeviceType;
  }
  
  export interface IAmbienteCompartilhado {
    isDev: boolean;
    versao: string;
    whatsAppLink: string;
    saurusUtilsWebService: string;
  }
  
  export interface IAmbientes {
    comum: IAmbienteCompartilhado;
    menuVip: IAmbiente;
  }
  