import React from "react"
import { FasesHeaderProps } from "./fases-header-props"
import { Box, Divider, IconButton, Typography } from "@material-ui/core"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useStyles } from "./fases-header-styles";

export const FasesHeader = ({
    titulo,
    icon: Icon,
    voltar,
}: FasesHeaderProps) => {

    const classes = useStyles()

    return (
        <Box className={classes.header}>
            <Box className={classes.container}>
                <IconButton
                    onClick={() => voltar()}
                    className={classes.iconButton}
                >
                    <ArrowBackIosIcon className={classes.iconVoltar} />
                </IconButton>
                <Box display="flex" alignItems="center">
                    <Box className={classes.content}>
                        {Icon && <Icon tipo="INPUT" class={classes.icon} />}
                    </Box>
                    <Typography
                        className={classes.label}
                    >
                        {typeof titulo === 'string' ? titulo.toUpperCase() : titulo}
                    </Typography>
                </Box>
            </Box>
            <Divider />
        </Box>
    )
}