import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '600px',
        zIndex: 99999,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        padding: '1rem',
    },
    textoTotais: {
        fontWeight: 500,
    },
    buttonPedir: {
        marginLeft: '1rem',
        [theme.breakpoints.down('sm')]: { marginLeft: 'unset' },
    },
    btnFooter: {
        marginTop: '1rem',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    grdBtns: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        },
    },
    scrollable: {
        position: 'relative',
        overflowY: 'auto',
        marginTop: '.5rem',
        [theme.breakpoints.down('sm')]: {},
        '&::-webkit-scrollbar': {
            width: '18px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            backgroundColor: 'transparent ',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,.15)',
            },
        },
    },
    scrollPage: {
        '&::-webkit-scrollbar': { width: '0px' },
    },
    sacolaVazia: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    imgSacolaVazia: {
        [theme.breakpoints.down('sm')]: { height: '200px' },
    },
    footer: {
        marginTop: 'auto',
    },
    carregando: {
        backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
    },
    mesa: {
        fontSize: '24px',
        fontWeight: 500
    },
    boxContainer: {
        flex: '1 0 auto',
        height: '100%',
        display: 'flex',
        outline: '0',
        overflowY: 'auto',
        flexDirection: 'column',
        '-webkit-overflow-scrolling': 'touch'
    },
    text: {
        fontSize: 22,
        fontWeight: "bold",
    },
    contianerBotao: {
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            justifyContent: "center",
        },
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "8px",
        marginBottom: "8px",
    },
    btnVoltar: {
        paddingRight: "10px",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 0",
            paddingRight: "0",
        },
        '& svg': {
            margin: 0,
            transform: 'rotate(180deg)',
            marginLeft: theme.spacing(1)
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            position: 'initial'
        }
    },
    textFieldAlignCenter: {
        '& input': {
            textAlign: 'center',
            color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.text.primary,
            fontWeight: 700,
            fontSize: 20
        },
        height: 50
    }
}));
