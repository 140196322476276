import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { useQuery, QueryConfig } from 'react-query';
import { useQueryConfig } from 'api/utils/use-query-config';
import { MergeDeep } from 'utils';
import { CardapioDadosProps } from 'modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props';
import { CardapioStorageKeys, useCardapioStorage } from 'storage/cardapio-storage';

interface GetEmpresaProps {
  empresaId: string;
}

class GetEmpresa implements ApiQuery<GetEmpresaProps, CardapioDadosProps> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: GetEmpresaProps): any[] {
    return ['GetEmpresa', params];
  }

  async execute(_: any, empresa: GetEmpresaProps): Promise<CardapioDadosProps> {
    return await FetchCardapioApiAsPublicUserJSON<CardapioDadosProps>(empresa.empresaId, `api/v4/empresa/${empresa.empresaId}`);
  }
}

export const getEmpresa = new GetEmpresa();

export const useGetEmpresa = (props: GetEmpresaProps, config: QueryConfig<CardapioDadosProps>) => {
  const defaultConfig = useQueryConfig();
  const { setRegistro } = useCardapioStorage()

  const res = useQuery(getEmpresa.getKey(props), getEmpresa.execute, MergeDeep(defaultConfig, config));

  try {
    if (res.data) {
      setRegistro(CardapioStorageKeys.DadosGerais, {
        ...res.data,
        configuracoes: {
          ...res.data!.configuracoes,
          capa: '',
          logo: ''
        }
      }, false)
    }
  } catch (err: any) {
    console.log(err)
  }

  return res;
};
