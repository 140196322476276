import React, { useRef, useState, useCallback, memo, useEffect, useMemo } from 'react';
import QRCode from 'qrcode.react';
import { Tooltip, Typography, TextField, MenuItem, Paper, Divider, Grid, FormControl, FormLabel, Switch, Box, Button, Fab, useMediaQuery, useTheme } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { SalaoModel } from 'api/pedidos/models/salao-model/salao-model';
import { obterSalao } from 'api/pedidos/queries/obter-saloes/obter-saloes';
import { useNotificacoes } from 'componentes/notificacoes';
import { MesaModel } from 'api/pedidos/models/mesa-model/mesa-model';
import { useStyles } from './qr-code-style';
import { isEmpty } from 'lodash';
import { Carregando } from 'componentes/carregando';
import classNames from 'classnames';
import { MesaQrCodeListData } from './components/qr-mesa-list-data';
import { exportComponentAsPNG } from 'react-component-export-image';
import GetAppIcon from '@material-ui/icons/GetApp';
import { obterListMesa } from 'api/pedidos/queries';
import { TemaModel } from 'api/cardapio/models/tema/tema-model';
import { EmpresaModel } from 'api/cardapio/models/empresa/empresa-model';

export interface QRCodeModalProps {
  empresaId: string;
  nomeEmpresa?: string;
  infoEmpresa?: string;
  empresaMaster: EmpresaModel;
  tema: TemaModel;
  aberto: boolean;
  // pesquisarMesa: (palavraChave?: string) => MesaModel[];
}

interface QuantidadeMesa {
  mesaMin: number
  mesaMax: number
}

const QRCodeModalComp = ({
  empresaId,
  infoEmpresa,
  nomeEmpresa,
  empresaMaster,
  tema,
  aberto,

}: QRCodeModalProps) => {
  //Style
  const classes = useStyles();

  //States
  const [tamanho, setTamanho] = useState(100);
  const [salao, setSalao] = useState<SalaoModel[]>([]);
  const [salaoId, setSalaoId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showButtons, setShowButtons] = useState<boolean>(true)
  const matchesPrint = useMediaQuery("print")
  const [mesa, setMesa] = useState<MesaModel[]>([]);
  const useTema = useTheme();
  const isMobile = useMediaQuery(useTema.breakpoints.down('sm'));
  const [escolherQR, setEscolherQR] = useState<boolean>(false);
  const [opcoes, setOpcoes] = useState({
    titulo: 1,
    info: 1,
    borda: 0,
  });
  const [quantidadeMesa, setQuantidadeMesa] = useState<QuantidadeMesa>({
    mesaMin: 0,
    mesaMax: 0
  })

  const [quantidadeMesaInput, setQuantidadeMesaInput] = useState<QuantidadeMesa>({
    mesaMin: 0,
    mesaMax: 0
  })

  const { showErrorMessage } = useNotificacoes();
  const componentRef = useRef(null);
  const [carregando, setCarregando] = useState(true);

  const alterarQuantidadeFilter = useCallback(() => {
    if (isNaN(quantidadeMesaInput.mesaMax) || isNaN(quantidadeMesaInput.mesaMin)) {
      return showErrorMessage(`Os valores estão inválidos.`)
    }
    setQuantidadeMesa(quantidadeMesaInput)
  }, [quantidadeMesaInput, showErrorMessage])

  useEffect(() => {
    if (aberto)
      setTimeout(() => {
        setCarregando(false);
      }, 1);
  }, [aberto]);

  const alterarQuantMesa = useCallback((ev: any) => {
    setQuantidadeMesaInput(prev => ({
      ...prev,
      [ev.target.name]: ev.target.value
    }))
  }, [])

  //Opção Selects
  const TAMANHOS = [
    {
      value: 100,
      label: 'Pequeno',
    },
    {
      value: 300,
      label: 'Médio',
    },
    {
      value: 500,
      label: 'Grande',
    },
  ];
  const TITULO = [
    {
      value: 1,
      label: 'Mostrar título',
    },
    {
      value: 0,
      label: 'Não mostrar título',
    },
  ];

  const INFORMACAO = [
    {
      value: 1,
      label: 'Mostrar informação',
    },
    {
      value: 0,
      label: 'Não mostrar informação',
    },
  ];

  const BORDA = [
    {
      value: 0,
      label: 'Não Mostrar borda',
    },
    {
      value: 1,
      label: 'Mostrar borda',
    },
  ];

  //Validação do telefone
  const validarTelefone = useCallback((valor: string) => {
    if (isEmpty(valor))
      return '.';

    return `, ou entre em contato conosco pelo telefone ${valor}.`;
  }, [])

  //Request do Salão
  const getSalaoWrapper = useCallback(async () => {
    try {
      const ret = await obterSalao.execute(empresaId);

      if (ret) {
        ret.push({ id: '00000000-0000-0000-0000-000000000000', descricao: 'Sem salão' })
      }

      setSalao(ret);
    }
    catch (err: any) {
      showErrorMessage(`Erro ao pesquisar salão. Detalhe: ${err.message}${validarTelefone(empresaMaster?.fone!)}`)
    }
  }, [empresaId, empresaMaster?.fone, showErrorMessage, validarTelefone]);

  //Request da Mesa
  const getMesaWrapper = useCallback(async () => {
    try {
      const ret = await obterListMesa.execute(empresaId, salaoId);
      const mesas: MesaModel[] = ret
      const mesasComQrCode = mesas.filter((mesa:MesaModel )=> mesa.qrCodeNaMesa)
      setMesa(mesasComQrCode);
    } catch (err: any) {
      showErrorMessage(`Erro ao pesquisar a mesa. Detalhe: ${err.message}${validarTelefone(empresaMaster?.fone!)}`);
    }
  }, [empresaId, empresaMaster?.fone, salaoId, showErrorMessage, validarTelefone]);

  //Render do Salão e da Mesa
  useEffect(() => {
    getSalaoWrapper();
    if (salaoId)
      getMesaWrapper();
  }, [getMesaWrapper, getSalaoWrapper, salaoId])

  //HandleChange do Salão
  const handleChangeSalao = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalaoId(event.target.value);
  }, []);

  //HandleChange do tamanho do Código QR
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      switch (event.target.name) {
        case 'tamanho':
          setTamanho(parseInt(event.target.value));
          break;
        default:
          setOpcoes({ ...opcoes, [event.target.name]: parseInt(event.target.value) });
          break;
      }
    },
    [opcoes],
  );

  const handleChangeChecked = useCallback((e: any) => {
    setEscolherQR(e.target.checked);
  }, [])

  const mesaQrCodeListData = useMemo(() => (
    <MesaQrCodeListData
      list={mesa}
      empresaId={empresaId}
      tamanho={tamanho}
      mesaMax={quantidadeMesa.mesaMax}
      mesaMin={quantidadeMesa.mesaMin}
      componentRef={componentRef}
      nomeEmpresa={nomeEmpresa!}
      infoEmpresa={infoEmpresa!}
      opcaoInfo={opcoes.info}
      opcaoTitulo={opcoes.titulo}
      opcaoBorda={opcoes.borda}
    />
  ), [empresaId, infoEmpresa, mesa, nomeEmpresa, opcoes.borda, opcoes.info, opcoes.titulo, quantidadeMesa.mesaMax, quantidadeMesa.mesaMin, tamanho]);

  const downloadMesa = useCallback(() => {
    setShowButtons(false);
    setTimeout(() => {
      exportComponentAsPNG(componentRef, {
        fileName: `${(nomeEmpresa || '').length > 0 ? `${nomeEmpresa}_` : ''}QRcode`,
        html2CanvasOptions: {},
      })
      setShowButtons(true);
    }, 1)
  }, [nomeEmpresa])

  if (carregando) {
    return (
      <Carregando
        className={"carregando"}
        carregando={true}
        modelo="normal"
        titulo={"Carregando os dados"}
        mensagem={"Por favor aguarde enquanto carregamos as informações..."}
      ></Carregando>
    );
  }

  return (
    <Paper className={classes.form} elevation={0} component="form">
      <Typography
        variant='h6'
        className={"typographyMarginBottom"}
      >
        <strong>Gerar Código QR</strong>
        <Divider />
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={isMobile ? 6 : 2} md={isMobile ? 4 : 3}>
          <TextField
            className={classes.fieldTamanho}
            id="select-titulo"
            select
            name="titulo"
            label="Título"
            value={opcoes.titulo}
            onChange={handleChange}
            helperText=""
          >
            {TITULO.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={isMobile ? 6 : 2} md={isMobile ? 4 : 3}>
          <TextField
            className={classes.fieldTamanho}
            id="select-info"
            select
            label="Informação"
            name="info"
            value={opcoes.info}
            onChange={handleChange}
            helperText=""
          >
            {INFORMACAO.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={isMobile ? 6 : 3} md={isMobile ? 4 : 3}>
          <TextField
            className={classes.fieldTamanho}
            id="select-tamanho"
            select
            name="tamanho"
            label="Tamanho"
            value={tamanho}
            onChange={handleChange}
            helperText=""
          >
            {TAMANHOS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={isMobile ? 6 : 2} md={isMobile ? 4 : 3}>
          <TextField
            className={classes.fieldTamanho}
            id="select-borda"
            select
            name="borda"
            label="Borda"
            value={opcoes.borda}
            onChange={handleChange}
            helperText=""
          >
            {BORDA.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={isMobile ? 6 : 2} md={isMobile ? 4 : 3}>
          <FormControl component="fieldset" >
            <FormLabel component="legend">Habilitar QR Code Mesa</FormLabel>
            <Switch
              checked={escolherQR}
              color="primary"
              onChange={handleChangeChecked}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </FormControl>
        </Grid>

        {escolherQR && (
          <>
            <Grid item xs={isMobile ? 6 : 2} md={isMobile ? 4 : 3}>
              <TextField
                className={classes.fieldTamanho}
                id="select-salao"
                select
                label="Salão"
                value={salao?.filter(x => x.id === salaoId)[0]?.id}
                onChange={handleChangeSalao}
                defaultValue="00000000-0000-0000-0000-000000000000"
              >
                {salao?.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={isMobile ? 3 : 2} md={isMobile ? 3 : 2}>
              <TextField
                className={classes.fieldTamanho}
                name='mesaMin'
                label="Mínimo"
                value={quantidadeMesaInput.mesaMin}
                onChange={alterarQuantMesa}
                helperText=""
              />
            </Grid>
            <Grid item xs={isMobile ? 3 : 2} md={isMobile ? 3 : 2}>
              <TextField
                className={classes.fieldTamanho}
                name='mesaMax'
                label="Máximo"
                value={quantidadeMesaInput.mesaMax}
                onChange={alterarQuantMesa}
                helperText=""
              />
            </Grid>
            <Grid item xs={isMobile ? 6 : 2} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Tooltip title="Pesquisar" placement='top'>
                <Button fullWidth={true} onClick={() => alterarQuantidadeFilter()}>Pesquisar</Button>
              </Tooltip>
            </Grid>
            <Grid item xs={isMobile ? 12 : 2} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Tooltip title="Imprimir Todos" placement="top">
                <Button fullWidth={true} onClick={() => {
                  let breakPoint = tamanho === 100 ? (nomeEmpresa && nomeEmpresa.length > 15 ? 4 : 8) : tamanho === 300 ? 4 : 1
                  const divs = document.querySelector('#print')?.children

                  const divsArray = Array.from(divs || [])

                  const qrCodes = Array.from(divsArray.map(element => {
                    return element?.children[0]?.children[0]
                  }))

                  const stringDivSeparado = qrCodes.reduce((prev, current, index) => {
                    if (current?.innerHTML === undefined) {
                      return `Sem Salão`
                    }
                    if (index === 0) {
                      return `<div style='page-break-after: always; display:flex; flex-wrap: wrap; gap: 20px; justify-content: center; text-align: center'>
                  <div style='display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 10px; ${opcoes.borda ? 'border: 1px solid black' : ''}'>${current?.innerHTML}</div>`
                    }
                    if ((index) % breakPoint === 0) {
                      return `${prev}</div><div style='page-break-after: always; display:flex; flex-wrap: wrap; gap: 20px; justify-content: center; text-align: center'>
                  <div style='display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 10px; ${opcoes.borda ? 'border: 1px solid black' : ''}'>${current?.innerHTML}</div>`
                    } else {
                      return `${prev}<div style='display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 10px; ${opcoes.borda ? 'border: 1px solid black' : ''}'>
                  ${current?.innerHTML}</div>`
                    }
                  }, '')

                  const newWindow = window.open('');
                  newWindow?.self.document.write(`${stringDivSeparado}`);
                  newWindow?.self.print();
                  newWindow?.self.close();
                }} style={{ marginRight: 10 }}>
                  Imprimir Todos
                  <PrintIcon style={{ paddingLeft: '5px' }} className={classes.icon} />
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>

      <Typography
        variant='h6'
        className={classNames(classes.typographyMarginTop, classes.typographyMarginBottom)}
      >

        <strong>Qr Code</strong>
        <Divider />
      </Typography>

      <div>
        {escolherQR ? (
          <>
            <Box className={classes.qrCodeContainer} id='print'>
              {salaoId === '00000000-0000-0000-0000-000000000000' || mesa.length === 0 ? (
                <Typography
                  variant="body1"
                  color="primary"
                  style={{
                    fontSize: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  Nenhum mesa encontrada
                </Typography>
              ) : mesaQrCodeListData}
            </Box>
          </>
        ) : (
          <>
            <Grid container style={{ justifyContent: 'center' }} spacing={2}>
              <div ref={componentRef} style={{ padding: '20px' }} className={classes.offSetImpressao}>
                <div
                  className={
                    opcoes.borda === 1
                      ? `${classes.AreaImpressao} ${classes.borda}`
                      : classes.AreaImpressao
                  }
                >
                  <div id='printCardapio'>
                    <div className={classes.titulo}>
                      {opcoes.titulo === 1 ? (
                        <Typography
                          variant="h4"
                          component="h2"
                          className={classes.nomeEmpresa}
                          gutterBottom={!(opcoes.titulo === 1)}
                        >
                          {nomeEmpresa}
                        </Typography>
                      ) : null}
                      {opcoes.info === 1 ? (
                        <Typography
                          variant="body1"
                          component="h2"
                          gutterBottom
                          className={classes.infoEmpresa}
                        >
                          {infoEmpresa}
                        </Typography>
                      ) : null}
                    </div>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                      <QRCode
                        value={`${window.location.origin}/${empresaId}`}
                        size={tamanho}
                        renderAs="svg"
                      />
                    </div>
                  </div>
                  {showButtons && (
                    <div style={{
                      display: (!matchesPrint) ? 'flex' : 'none',
                      justifyContent: 'center',
                      marginTop: '5px'
                    }}>
                      <Tooltip title="Imprimir" placement="top">
                        <Fab size='small' color="secondary" style={{ marginRight: 10 }}
                          onClick={() => {
                            const teste = document.querySelector(`#printCardapio`)?.innerHTML
                            const stringDiv = `<div style='display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;'>
                          <div style="${opcoes.borda ? 'border: 1px solid black;' : ''} padding: 10px">
                                    ${teste}
                                </div>
                            </div>`
                            const newWindow = window.open('');
                            newWindow?.self.document.write(`${stringDiv}`);
                            newWindow?.self.print();
                            newWindow?.self.close();
                          }}>
                          <PrintIcon fontSize="small" className={classes.icon} />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Download" placement="top">
                        <Fab
                          size='small'
                          onClick={downloadMesa}
                          color="secondary"
                        >
                          <GetAppIcon fontSize="small" className={classes.icon} />
                        </Fab>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </>
        )}
      </div>
    </Paper>
  );
};
export const QRCodeModal = memo(QRCodeModalComp);
