import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {

    const colorSacola =
        theme.palette.type === 'light'
            ? theme.palette.primary.main
            : theme.palette.text.primary;

    return {
        header: {
            width: '100%',
            minHeight: 60,
            display: 'flex',
            flexDirection: 'column',
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            padding: theme.spacing(1, 0),
            flex: 1,
        },
        content: {
            marginRight: 8,
            display: "flex",
            [theme.breakpoints.down('sm')]: {
                marginRight: 10
            }
        },
        iconVoltar: {
            paddingLeft: theme.spacing(1),
            fontSize: '32px',
            color: colorSacola
        },
        icon: {
            fill: colorSacola,
            height: theme.spacing(4.5),
            width: theme.spacing(4.5),
        },
        label: {
            fontWeight: "bold",
            fontSize: "24px",
            fontStyle: "revert",
            color: colorSacola,
            lineHeight: 0,
        },
        iconSacola: {
            flex: 1,
            height: '45px',
            marginBottom: '5px',
            marginRight: '80px'
        },
        iconButton: {
            position: 'absolute',
            top: 0,
            left: 0,
            padding: theme.spacing(1, 1),
            marginTop: theme.spacing(1)
        }
    }
})