import { guidEmpty } from 'utils/guid-empty';
import { useCallback, useRef } from 'react';
import { newGuid } from 'utils/new-guid';
import { EnumTpCalculoModificador, EnumTpProduto, MovSimplesProdutoModel, ProdutoNovoSubItemModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { ProdutoModel } from 'api/cardapio/models/produto/produto-model';
import { useMovProd } from 'hooks/mov-prod';
import { CardapioStorageKeys, useCardapioStorage } from 'storage/cardapio-storage';
import { useGetProdutoSubItens } from 'api/cardapio/queries/produto/get-produto-subitens';
import { ProdutoModificadorModel, ProdutoSubItensModel } from 'api/cardapio/models/produto/produto-subitens-model';
import { MovSimplesModificadoresProdutoModel } from 'api/cardapio/models/produto/mov-simples-modificadores-produto-model';
import { useGetProdutoModificadores } from 'api/cardapio/queries/produto/get-produto-modificador';

interface StorageProdSubItemProps {
  produtos: Array<{
    produtoId: string;
    variacaoId: string;
    prodSubItens: ProdutoSubItensModel[]
    modificadorId?: string;
  }>
  dateExpired: Date
}

const useSubItens = () => {
  // STATES E REFS
  const allProducts = useRef<MovSimplesProdutoModel[]>([]);

  // PROVIDERS
  const { retornaModelMovSimples } = useMovProd()
  const { setRegistro, getRegistro, delRegistro, exists } = useCardapioStorage()
  const token = getRegistro(CardapioStorageKeys.TokenIntegrador, false)
  const { getProdutoSubItens } = useGetProdutoSubItens();
  const { getProdutoModificadores } = useGetProdutoModificadores();
  const subItemKey = CardapioStorageKeys.SubItensTemporarios

  const dateExpired = useCallback(() => {
    const data = new Date()
    data.setHours(data.getHours() + 8)

    return data
  }, [])

  const deleteSubItens = useCallback(() => {
    delRegistro(subItemKey)
  }, [delRegistro, subItemKey])

  const getSubItemStorage = useCallback((produtoId: string, variacaoId: string) => {
    const subitens = getRegistro(subItemKey) as StorageProdSubItemProps
    if (!exists(subItemKey)) {
      return undefined
    }

    if (subitens.dateExpired < new Date()) {
      deleteSubItens()
      return undefined
    }

    const subItens = subitens.produtos.find(prod =>
      prod.produtoId === produtoId && prod.variacaoId === variacaoId)?.prodSubItens

    return subItens ?? undefined
  }, [deleteSubItens, exists, getRegistro, subItemKey])

  const setSubItemStorage = useCallback((produtoId: string, variacaoId: string, produtos: ProdutoSubItensModel[]) => {
    let existe = true
    const newProd = {
      produtoId,
      variacaoId,
      prodSubItens: produtos
    }
    const subitens = getRegistro(subItemKey) as StorageProdSubItemProps

    if (subitens.dateExpired < new Date()) {
      deleteSubItens()
      existe = false
    }

    if (exists(subItemKey) && existe) {
      setRegistro(subItemKey, {
        ...subitens,
        produtos: [
          ...subitens.produtos,
          newProd
        ]
      } as StorageProdSubItemProps)
      return
    }

    setRegistro(subItemKey, {
      dateExpired: dateExpired(),
      produtos: [
        newProd
      ]
    } as StorageProdSubItemProps)
  }, [dateExpired, deleteSubItens, exists, getRegistro, setRegistro, subItemKey])

  const transformarProduto = useCallback((produtoSubItem: ProdutoSubItensModel[]) => {

    return produtoSubItem
      .filter(prod => prod.produtoSubGradeDetalhado)
      .map(prod => {
        const detalhado = prod.produtoSubGradeDetalhado
        const produto: ProdutoModel = {
          ...new ProdutoModel(),
          ativo: detalhado.ativo,
          balanca: detalhado.balanca,
          categoriaId: detalhado.categoriaId,
          cobraTaxaServico: detalhado.cobraTaxaServico,
          codigo: detalhado.codigos[0]?.codigo ?? '',
          codigos: detalhado.codigos,
          contemSubItem: detalhado.contemSubItem,
          empresaId: token.empresaId,
          favorito: detalhado.favorito,
          id: newGuid(),
          imagemUrl: detalhado.imagemUrl,
          medida: detalhado.medida,
          medidaDesc: detalhado.medidaDesc,
          ncm: detalhado.ncm,
          ncmId: detalhado.ncmId,
          nome: detalhado.nome,
          prodSubItem: [],
          produtoGradeId: prod.produtoSubGradeId,
          produtoId: prod.produtoSubId,
          setorId: detalhado.setorId,
          tipo: detalhado.tipoProduto,
          vPreco: detalhado.vPreco,
          infAdic: detalhado.infAdic
        }

        return produto
      })
  }, [token.empresaId])

  const transformarInfoSubItem = useCallback((produtoSubItem: ProdutoSubItensModel[]) => {
    return produtoSubItem.map(prod => {
      const subitem: ProdutoNovoSubItemModel = {
        ...new ProdutoNovoSubItemModel(),
        fator: prod.fator,
        id: prod.id,
        modificadorId: prod.modificadorId,
        ordem: prod.ordem,
        produtoGradeId: prod.produtoGradeId,
        produtoSubGradeId: prod.produtoSubGradeId,
        qMax: prod.qMax,
        qMin: prod.qMin,
        qPadrao: prod.qPadrao,
        tipo: prod.tipo
      }

      return subitem
    })
  }, [])

  const prodSubItensWrapper = useCallback(async (produtoId: string, produtoGradeId: string) => {
    const res = await getProdutoSubItens(produtoId, produtoGradeId)
    if (res.erro) throw res.erro
    return res.resultado?.data as ProdutoSubItensModel[]
  }, [getProdutoSubItens]);

  const prodModificadorWrapper = useCallback(async (modificadorId: string) => {
    const res = await getProdutoModificadores(modificadorId)
    if (res.erro) throw res.erro
    return res.resultado?.data as ProdutoModificadorModel;
  }, [getProdutoModificadores])

  const handleProdSubItens = useCallback(async (produtoId: string, produtoGradeId: string) => {
    const getFromStorage = getSubItemStorage(produtoId, produtoGradeId)

    const produtos = getFromStorage ? getFromStorage : await prodSubItensWrapper(produtoId, produtoGradeId)
    setSubItemStorage(produtoId, produtoGradeId, produtos)
    return produtos
  }, [getSubItemStorage, prodSubItensWrapper, setSubItemStorage])

  const getFullSubItens = useCallback(
    async (product: MovSimplesProdutoModel, idGroup: string) => {
      let productsSubItens: MovSimplesProdutoModel[] = [];
      let productsModifier: MovSimplesModificadoresProdutoModel[] = [];
      let p: MovSimplesProdutoModel | undefined = undefined;
      const prodSubItens = await handleProdSubItens(product.produtoId ?? '', product.produtoGradeId ?? '')
      const produtos = transformarProduto(prodSubItens)
      const subItens = transformarInfoSubItem(prodSubItens)

      if (subItens?.length && subItens.length > 0) {
        for await (let item of subItens) {
          if (item.modificadorId) {
            const isModifier = await prodModificadorWrapper(item.modificadorId);

            const newIdModificador = newGuid();

            if (isModifier) {
              const produtos: MovSimplesProdutoModel[] = [];

              for (let prod of isModifier.produtos) {
                let produtoModel: ProdutoModel | undefined = {
                  ...new ProdutoModel(),
                  ativo: prod.produtoGrade.ativo,
                  balanca: prod.produtoGrade.balanca,
                  categoriaId: prod.produtoGrade.categoriaId,
                  cobraTaxaServico: prod.produtoGrade.cobraTaxaServico,
                  codigo: prod.produtoGrade.codigos.length > 0 ? prod.produtoGrade.codigos[0]?.codigo ?? '' : '',
                  codigos: prod.produtoGrade.codigos,
                  contemSubItem: prod.produtoGrade.contemSubItem,
                  empresaId: token.empresaId,
                  favorito: prod.produtoGrade.favorito,
                  id: newGuid(),
                  imagemUrl: prod.produtoGrade.imagemUrl,
                  medida: prod.produtoGrade.medida,
                  medidaDesc: prod.produtoGrade.medidaDesc,
                  ncm: prod.produtoGrade.ncm,
                  ncmId: prod.produtoGrade.ncmId,
                  nome: prod.produtoGrade.nome,
                  prodSubItem: [],
                  produtoGradeId: prod.produtoGradeId,
                  produtoId: prod.produtoGrade.produtoId,
                  setorId: prod.produtoGrade.setorId,
                  tipo: prod.produtoGrade.tipoProduto,
                  vPreco: prod.produtoGrade.vPreco,
                  infAdic: prod.produtoGrade.infAdic
                }

                let produto: MovSimplesProdutoModel | undefined =
                  retornaModelMovSimples(produtoModel);

                const isProductDefault =
                  item.produtoSubGradeId === produto.produtoGradeId;

                const valorProduto = prod.valor > 0 ? prod.valor : produto.vUnCom;

                produto = {
                  ...produto,
                  vUnComOrig: valorProduto,
                  vUnCom: valorProduto,
                  qCom: isProductDefault ? item.qPadrao : 0,
                  vProd: (isProductDefault ? item.qPadrao : 0) * valorProduto,
                  vFinal: (isProductDefault ? item.qPadrao : 0) * valorProduto,
                  id: newGuid(),
                  infoSubItem: { ...item, qPadrao: isProductDefault ? item.qPadrao : 0, modificadorTipoCalculo: isModifier.tpCalculo as EnumTpCalculoModificador },
                  idDoProdutoPaiInfoSubItem: product.id,
                  produtoPaiId: product.produtoId,
                  idGroup: idGroup,
                  nivel: product.nivel + 1,
                  tpProduto:
                    (isProductDefault ? item.qPadrao : 0) <= 0
                      ? EnumTpProduto.Opcional
                      : EnumTpProduto.Insumo,
                  modificadorId: isModifier.id,
                  indFin: isProductDefault && item.qPadrao > 0,
                  ordem: prod.ordem,
                  modificadorUnicoId: newIdModificador,
                };
                if ((produto?.subItens && produto?.subItens?.length > 0) || produto.contemSubItem) {
                  p = await getFullSubItens(produto, idGroup);
                }

                allProducts.current = [...allProducts.current, p ?? produto];

                productsSubItens.push(p ?? produto);

                p = undefined;
                produto = undefined;

              }

              let m: MovSimplesModificadoresProdutoModel | undefined = {
                id: isModifier.id ?? '',
                empresaId: isModifier?.empresaId ?? '',
                contratoId: isModifier?.contratoId ?? '',
                descricao: isModifier?.descricao ?? '',
                nome: isModifier?.nome ?? '',
                fator: item.fator,
                qMax: item.qMax,
                qMin: item.qMin,
                qPadrao: item.qPadrao,
                produtoSubGradeIdPadrao: item.produtoSubGradeId,
                produtos: produtos,
                qAtual: item.qPadrao,
                tpCalculo: isModifier.tpCalculo,
                valorItemPadrao: 0,
                descontoPadrao: 0,
                unicoId: newIdModificador
              };

              productsModifier.push(m);

              m = undefined;
            }
          } else if (item.produtoSubGradeId) {
            const produto = produtos.find(x => item.produtoSubGradeId === x.produtoGradeId) as ProdutoModel;

            if (!produto) {
              throw new Error('Não conseguimos achar o produto deste subItem.')
            }
            let prod: MovSimplesProdutoModel | undefined = retornaModelMovSimples(produto);

            const newId = newGuid()
            prod = {
              ...prod,
              vUnComOrig: prod.vUnCom,
              qCom: item.qPadrao,
              vProd: item.qPadrao * prod.vUnCom,
              vFinal: item.qPadrao * prod.vUnCom,
              id: newId,
              infoSubItem: { ...item, modificadorTipoCalculo: 0 as EnumTpCalculoModificador },
              idDoProdutoPaiInfoSubItem: product.id,
              produtoPaiId: product.produtoId,
              idGroup: idGroup,
              nivel: product.nivel + 1,
              tpProduto:
                item.qPadrao <= 0
                  ? EnumTpProduto.Opcional
                  : EnumTpProduto.Insumo,
            };

            if ((prod?.subItens && prod?.subItens?.length > 0) || prod.contemSubItem) {
              p = await getFullSubItens(prod, idGroup);
            }

            allProducts.current = [...allProducts.current, prod];
            productsSubItens.push(p ?? prod);
            p = undefined
            prod = undefined
          }
        }
      }

      const verificarItens = productsModifier.filter(m => !m.produtoSubGradeIdPadrao)

      const verificarDescontoModificador = verificarItens.map(m => {
        return productsSubItens.filter(p => p.modificadorId === m.id).reduce((atual, current) => {
          if (atual?.vUnCom > 0) {
            if (atual.vUnCom < current.vUnCom) {
              return atual
            } else {
              return current
            }
          }

          if (Object.keys(atual).length === 0) {
            return current
          }

          return atual;
        }, {} as MovSimplesProdutoModel);
      })

      const valuePai = product.vUnCom;
      const valueTotalSubItens = productsSubItens.filter(p => p.qCom > 0).reduce(
        (acc, cur) => acc + (cur.vUnCom * cur.qCom),
        0
      ) + verificarDescontoModificador.reduce(
        (acc, cur) => acc + cur.vUnCom,
        0
      )

      let descontoTotal = 0;

      if (valueTotalSubItens > valuePai) {
        descontoTotal = Number((valueTotalSubItens - valuePai).toFixed(2));
      }

      let produtosComDesconto = productsSubItens.map((item) => {
        const descontoPonderadoDoProduto = Number(
          (((verificarDescontoModificador.find((p) => p.id === item.id) ? item.vUnCom : item.vUnCom * item.qCom) / valueTotalSubItens) * descontoTotal).toFixed(3)
        );

        const prod = {
          ...item,
          vDescUsuario: verificarDescontoModificador.find((p) => p.id === item.id)
            ? descontoPonderadoDoProduto
            : item.qCom === 0 || item.vProd === 0
              ? 0
              : descontoPonderadoDoProduto
        };

        return prod;
      })

      const valorDosProdutosComDesconto = +(produtosComDesconto.filter(p => p.vDescUsuario > 0).reduce((acc, current) => acc + (current.vUnCom + current.vAcrescUsuario - current.vDescUsuario), 0).toFixed(2))

      if (valorDosProdutosComDesconto > product.vFinal) {
        const diferenca = +((valorDosProdutosComDesconto - (product.vProd + product.vAcrescUsuario - product.vDescUsuario)).toFixed(2));

        const produtoCommaiorpreco = produtosComDesconto.filter(p => p.qCom > 0).reduce((acc, current) => {
          if (acc?.qCom > 0) {
            if (acc.vFinal > current.vFinal) {
              return acc
            } else {
              return current
            }
          }

          if (Object.keys(acc).length === 0) {
            return current
          }

          return acc;
        }, {} as MovSimplesProdutoModel);

        produtosComDesconto = produtosComDesconto.map(p => {
          if (p.id === produtoCommaiorpreco.id) {
            const valorAtt = produtoCommaiorpreco.vDescUsuario + diferenca

            return { ...produtoCommaiorpreco, vDescUsuario: valorAtt }
          }

          return p
        });

      }

      const produtoComSubItem = {
        ...product,
        idGroup: idGroup,
        modificadores: descontoTotal <= 0 ? productsModifier : productsModifier.map(m => {

          const produtosDoModificador = produtosComDesconto.filter(p => p.modificadorId === m.id && (p.qCom > 0 || verificarDescontoModificador.find(prod => prod.id === p.id)));

          if (produtosDoModificador.length > 0) {
            const descontoDoItemModificador = produtosDoModificador.reduce((acc, current) => acc + current.vDescUsuario, 0);

            return {
              ...m,
              descontoPadrao: descontoDoItemModificador,
              valorItemPadrao: verificarDescontoModificador.find(prod => prod.modificadorId === m.id) ? verificarDescontoModificador.find(prod => prod.modificadorId === m.id)?.vUnCom ?? 0 : produtosDoModificador[0]?.vProd ?? 0
            }
          }

          return m
        }),
        prodSubItem:
          descontoTotal <= 0
            ? productsSubItens
            : produtosComDesconto
      };

      produtosComDesconto = [];

      return produtoComSubItem;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [retornaModelMovSimples, transformarInfoSubItem, transformarProduto]
  );

  const getProductForEditingWithAdditional = useCallback(
    async (product: MovSimplesProdutoModel, idGroup: string, adicionais: MovSimplesProdutoModel[], quantidadePai: number) => {
      const { prodSubItem } = product;
      let productsSubItens: MovSimplesProdutoModel[] = [];
      let p: MovSimplesProdutoModel | undefined = undefined;

      if (prodSubItem?.length && prodSubItem.length > 0) {
        for (let item of prodSubItem) {

          const temoAdicional = adicionais.find(p => p.idAdicional === item.id && p.idGroup === idGroup)
          const qtdAdicional = temoAdicional?.qCom ? (temoAdicional.qCom) : 0;

          const qtdPadao = item.qCom < item.infoSubItem!.qPadrao ? item.qCom : item.infoSubItem!.qPadrao;

          let prod = {
            ...item,
            qCom: (qtdPadao + qtdAdicional),
            vProd: (qtdPadao + qtdAdicional) * item.vUnCom,
            vFinal: (qtdPadao + qtdAdicional) * item.vUnCom,
            id: item.id !== guidEmpty() ? item.id : newGuid(),
            infoSubItem: item.infoSubItem,
            idDoProdutoPaiInfoSubItem: product.id,
            produtoPaiId: product.produtoId,
            idGroup: idGroup,
            nivel: product.nivel + 1
          };

          if (prod?.subItens && prod?.subItens?.length > 0) {
            p = await getProductForEditingWithAdditional(prod, idGroup, adicionais, quantidadePai);
          }

          allProducts.current = [...allProducts.current, prod];
          productsSubItens.push(p ?? prod);
          p = undefined

        }
      }

      const valuePai = product.vProd;
      const valueTotalSubItens =
        productsSubItens.reduce((acc, cur) => acc + cur.vProd, 0);

      let descontoTotal = 0;

      if (valueTotalSubItens > valuePai) {
        descontoTotal = Number((valueTotalSubItens - valuePai).toFixed(2));
      }

      const produtoComSubItem = {
        ...product,
        prodSubItem:
          descontoTotal <= 0
            ? productsSubItens
            : productsSubItens.map((item) => {
              const descontoPonderadoDoProduto = Number(
                ((item.vProd / valueTotalSubItens) * descontoTotal).toFixed(2)
              );

              return {
                ...item,
                vDescUsuario: descontoPonderadoDoProduto
              };
            })
      };

      return produtoComSubItem;
    },
    []
  );


  return {
    getFullSubItens,
    getProductForEditingWithAdditional,
    allProducts,
    retornaModelMovSimples
  };
};

export default useSubItens;
