import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    padding: "0",
    maxWidth: "245px",
    backgroundColor: 'rgb(255 255 255 / 70%)',
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: 'rgb(255 255 255 / 70%)',
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
    },
  },

  containerMeusPedidos: {
    border: "2px dashed",
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
    padding: "4px 8px",
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  textos: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: 500,
  },
}));
