import { useCallback } from 'react';
import { GetAuthLoginRedirect, timeStorage } from 'utils';
import { decodeToken } from "react-jwt";
import { ConvertTokenIntegrador } from 'utils/use-token';
import { TokenIntegradorModel } from 'api/master/models/token/token-integrador-model';

const TokenUsuarioObj = 'tokenMasterUsuario';

export const useTokenUsuario = () => {

  const tokenUsuario: TokenIntegradorModel | undefined = ConvertTokenIntegrador(timeStorage.load(TokenUsuarioObj));

  const usuarioToken: TokenIntegradorModel | undefined = (Object.keys(timeStorage.load(TokenUsuarioObj)).length > 0) ? ConvertTokenIntegrador(timeStorage.load(TokenUsuarioObj))
    : new TokenIntegradorModel();

  const Autenticado = (): boolean => !(usuarioToken!.exp * 1000 <= new Date().getTime());

  const TokenExpirado = useCallback((token: TokenIntegradorModel | undefined): boolean => {
    if (token) {
      let redirect = !(token?.exp * 1000 <= new Date().getTime())
      if (redirect === false) {
        window.location.assign(GetAuthLoginRedirect());
      }
      return redirect;
    }

    return false;
  }, []);

  const TokenValido = useCallback(
    (token: string | undefined) => {
      if (token) {
        let tokenModel = decodeToken(token) as TokenIntegradorModel;
        return TokenExpirado(tokenModel)
      }

      return false;
    }, [TokenExpirado])

  const RotaValida = useCallback((empresaId: string): boolean => {
    
    let token = timeStorage.load(TokenUsuarioObj);

    if (TokenValido(token)) {
      if (tokenUsuario?.empresa.filter(x => x?.Id === empresaId)[0]?.Id)
        return true;
    }

    return false
  }, [TokenValido, tokenUsuario])

  return {
    usuarioToken,
    TokenValido,
    TokenExpirado,
    Autenticado,
    RotaValida
  };
};

export const RemoverToken = () => {
  timeStorage.remove(TokenUsuarioObj);
}
