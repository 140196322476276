import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    padding: '.5rem',
    position: 'relative',
  },
  descricao: {
    marginBottom: '10px',
  },
  loading: {
    marginTop: '30px',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  spinner: {
    marginTop: '10px',
  },
  acoes: {
    display: 'flex',
    margin: '10px 0px',
    width: '100%',
    justifyContent: 'flex-end',
  },
  acao: {
    textTransform: 'none',
  },
  marginBottom: { marginBottom: '1rem' },
  typographyMarginTop: {
    marginTop: '1rem',
  },
  typographyMarginBottom: { marginBottom: '1rem' },
  carregando: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
  },
  tema: {
    marginTop: theme.spacing(2),
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  fullWidth: {
    width: '100% !important'
  },
  vincularMesa: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  cardMesa: {
    border: `4px dotted ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 400,
    height: 200,
    alignSelf: 'center',
    cursor: 'pointer',
    '& svg': {
      width: 50,
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 150,
    }
  },
  flexContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
