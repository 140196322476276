import React from 'react';
import { CardActionArea, Typography, Grid, Card, Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import semFoto from 'arquivos-estaticos/global/imagens/semFoto.png';
import { useStyles } from './item-sacola-card-styles';
import { EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import InfoProduto from './components/info-produto/info-produto';

export interface itemSacolaModalProps {
  item: MovSimplesProdutoModel;
  quandoEditar: (ItemSacola: MovSimplesProdutoModel) => void;
  quandoRemover: (idProduto: string) => void;
}
export const ItemSacolaCard = ({ item, quandoEditar, quandoRemover }: itemSacolaModalProps) => {
  const classes = useStyles();
  const wrapEditar = React.useCallback(
    (event, item) => {
      event.stopPropagation();
      quandoEditar(item);
    },
    [quandoEditar],
  );
  const wrapRemover = React.useCallback(
    (event, item) => {
      event.stopPropagation();
      quandoRemover(item);
    },
    [quandoRemover],
  );

  const valorAcumulado = item.adicionais.reduce(
    (acc, current) => acc + (current.vUnCom * (current.qComModificador > 0 ? current.qComModificador : current.qCom) - current.vDescUsuario + current.vAcrescUsuario),
    0
  );

  //Converte a imagem em base64 para mostar na tela
  let imagem = ''
  if (item?.imgUrl?.length! > 0) {
    try {
      imagem = atob(item?.imgUrl!)
    } catch {
      imagem = item?.imgUrl!
    }
  }

  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea className={classes.item} onClick={(event) => wrapEditar(event, item)}>
        <div>
          <img
            src={imagem! || semFoto}
            className={classes.img}
            alt={item.imgUrl}
          />
          <div className={classes.helperText}>
            <EditIcon className={classes.iconEditar} />
            <Typography variant="body2" style={{ fontSize: '8px' }} color="textPrimary">
              Clique para Editar
            </Typography>
          </div>
        </div>
        <div className={classes.info}>
          <Grid container direction="column" className={classes.infoText}>
            <Grid container item>
              <Box flex={1}>
                <Typography
                  gutterBottom
                  variant="h5"
                  color="textPrimary"
                  style={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    wordBreak: 'break-all',
                    flex: 1,
                  }}
                >
                  {item.xProd}
                </Typography>
              </Box>
              <DeleteIcon
                className={classes.iconBtn}
                onClick={(event) => wrapRemover(event, item.id)}
              />
            </Grid>
            {item.observacao!.length > 0 ? (
              <Grid style={{ marginTop: '.3rem' }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="justify"
                  className={classes.textWithCrop}
                  gutterBottom
                >
                  Obs.: {item.observacao}
                </Typography>
              </Grid>
            ) : null}
            <Grid item>
              {item.tpProduto !== EnumTpProduto.Combo && item.prodSubItem.length > 0 && item.prodSubItem.filter(p => p.qCom > 0).length > 0 && (
                <div>
                  <p className={classes.pAdicional}>
                    Inclusos
                  </p>
                </div>
              )}
              {item.prodSubItem.length > 0 &&
                item.prodSubItem.map((p) => (
                  <InfoProduto key={p.id} incluso produto={{ ...p, qCom: p.qCom > p.infoSubItem!.qPadrao ? p.infoSubItem!.qPadrao : p.qCom }} qtdPai={(item.qCom)} qtdTotal={item.prodSubItem.filter(p => p.qCom > 0)} />
                ))}

              {item.adicionais.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <p className={classes.pAdicional}>
                      Adicionais
                    </p>
                  </Grid>
                  {item.adicionais
                    .filter((p) => p.qCom > 0)
                    .map((p) => (
                      <InfoProduto key={p.id} produto={p} qtdPai={item.qComModificador > 0 ? item.qComModificador : item.qCom} qtdTotal={item.adicionais.filter(p => p.qCom > 0 && p.modificadorId)} />
                    ))}
                </>
              )}
            </Grid>

          </Grid>

          <Grid container justify="flex-end" alignItems="center">
            <Typography variant="h5" className={classes.textosFooter} color="textPrimary">
              {item.qCom}x {item.uCom}
            </Typography>
            <Typography variant="h5" className={classes.textoTotal} color="textPrimary">
              {((item.vFinal) + valorAcumulado)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </Typography>
          </Grid>
        </div>
      </CardActionArea>
    </Card>
  );
};
