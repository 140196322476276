import React, { MouseEvent, useState, useCallback, memo, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { ModalConfirmacao } from '..';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { GetAuthLoginRedirect } from 'utils';
import { useParams } from 'react-router-dom';
import { useNotificacoes } from 'componentes';
import { isEmpty } from 'lodash';
import { useStyles } from './cardapio-menu-conta-styles';
import { EmpresaModel } from 'api/cardapio/models/empresa/empresa-model';
import { postIdentidade } from 'api/master/mutations/identidade/post-identidade';

export interface CardapioMenuContaProps {
  logout: () => any;
  setCarregando: (carregando) => any;
  nomeUsuario?: string;
  somenteLeitura: boolean;
  rotaInvalida: boolean;
  tema: any;
  empresa: EmpresaModel;
}

export const CardapioMenuConta = ({
  logout,
  setCarregando,
  nomeUsuario,
  rotaInvalida,
  somenteLeitura,
  tema,
  empresa,
}: CardapioMenuContaProps) => {
  const classes = useStyles();
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [senhaAcesso, setSenhaAcesso] = useState('');
  const [nomeAdministrador, setNomeAdministrador] = useState<Boolean>(false);
  const { empresaId } = useParams<{ empresaId: string }>();
  const identidadeAdministrador = 'IdentidadeAdministrador';
  const { showErrorMessage } = useNotificacoes();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOpen = () => {
    setOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickClose = () => {
    setOpen(false);
    setCarregando(false);
  };

  const handleChange = event => {
    setSenhaAcesso(event.target.value);
  }

  const handleMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorMenu(event.currentTarget);
    },
    [setAnchorMenu]);

  const handleClose = useCallback(() => {
    setAnchorMenu(null);
  }, [setAnchorMenu]);

  const handleCloseWrapper = useCallback(
    (funcao, carregando?: boolean) => {
      handleClose();
      if (carregando) {
        setCarregando(carregando || false);
        carregando ? setTimeout(() => funcao(), 1) : funcao();
        window.location.assign(GetAuthLoginRedirect())
      }
      if (!carregando) {
        setCarregando(carregando || false);
        carregando ? setTimeout(() => funcao(), 1) : funcao();
      }
    },
    [handleClose, setCarregando],
  );

  const handleCloseWrapperAutoatendimento = useCallback((funcao, carregando?: boolean) => {
    handleClose();
    if (carregando) {
      setCarregando(carregando || false);
      carregando ? setTimeout(() => funcao(), 1) : funcao();
      handleClickOpen();
    }
    if (!carregando) {
      setCarregando(carregando || false);
      carregando ? setTimeout(() => funcao(), 1) : funcao();
      setNomeAdministrador(false);
    }
  }, [handleClose, setCarregando, handleClickOpen]);

  const handleModal = useCallback(() => {
    setModal((prev) => !prev);
  }, []);

  const validarTelefone = useCallback((valor: string) => {
    if (isEmpty(valor))
      return '.';

    return `, ou entre em contato conosco pelo telefone ${valor}.`;
  }, [])

  const requestSenhaAdministrador = useCallback(async () => {
    try {
      const request = await postIdentidade.execute({ empresaId: empresaId, senhaAcesso: senhaAcesso });
      localStorage.setItem(identidadeAdministrador, JSON.stringify(request));
      window.location.assign(GetAuthLoginRedirect());
      handleClickClose();
    } catch (e: any) {
      showErrorMessage(`Erro ao salvar as configurações do cardápio. Detalhe: ${e.message}${validarTelefone(empresa?.fone!)}`);
    }
  }, [empresa?.fone, empresaId, handleClickClose, senhaAcesso, showErrorMessage, validarTelefone])

  const funcaoStateNome = useCallback(() => {
    if (!rotaInvalida)
      setNomeAdministrador(true);
    else
      setNomeAdministrador(false);
  }, [setNomeAdministrador, rotaInvalida])

  useEffect(() => {
    funcaoStateNome();
  }, [funcaoStateNome, nomeAdministrador])

  if (open) {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle>{"Acesso Administrador"}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Para entar em modo de administrador, insira a sua senha de acesso!
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            value={senhaAcesso}
            onChange={handleChange}
            label="Senha de acesso"
            type="password"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClickClose} color="secondary">
            Cancelar
          </Button>
          <Button autoFocus onClick={requestSenhaAdministrador} color="primary">
            Acessar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <Grid item style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}>
        <Typography
          aria-haspopup="true"
          className={classNames(classes.menuConta)}
          onClick={handleMenu}
        >
          <AccountCircle style={{ fontSize: '35px' }} />
          {Boolean(tema.autoAtendimento) ? (
            <span className={nomeAdministrador ? classes.spanNome : classes.spanLogin}>
              Olá
              {nomeAdministrador
                ? ` ${('Administrador').split(' ')[0]}`
                : ', faça seu login ou cadastre-se'}
              {Boolean(anchorMenu) ? (
                <ExpandLessIcon
                  color="inherit"
                  className={nomeAdministrador ? classes.expandNome : classes.expandLogin}
                />
              ) : (
                <ExpandMoreIcon
                  color="inherit"
                  className={nomeAdministrador ? classes.expandNome : classes.expandLogin}
                />
              )}
            </span>
          ) : (
            <span className={rotaInvalida === false ? classes.spanNome : classes.spanLogin}>
              Olá
              {rotaInvalida === false
                ? ` ${(nomeUsuario || '').split(' ')[0]}`
                : ', faça seu login ou cadastre-se'}
              {Boolean(anchorMenu) ? (
                <ExpandLessIcon
                  color="inherit"
                  className={rotaInvalida === false ? classes.expandNome : classes.expandLogin}
                />
              ) : (
                <ExpandMoreIcon
                  color="inherit"
                  className={rotaInvalida === false ? classes.expandNome : classes.expandLogin}
                />
              )}
            </span>
          )}
        </Typography>
      </Grid>

      {anchorMenu ? (
        <>
          <Menu
            anchorEl={anchorMenu}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 40, horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={Boolean(anchorMenu)}
            onClose={handleClose}
          >
            {/* Login */}

            {Boolean(tema.autoAtendimento) ? (
              (nomeAdministrador ? (
                <MenuItem onClick={() => handleCloseWrapperAutoatendimento(logout, false)}>
                  <Typography
                    className={classNames(classes.btnMenu, classes.pointer)}
                    color="textPrimary"
                  >
                    <ExitToAppRoundedIcon color="inherit" style={{ marginRight: '5px' }} />
                    Deslogar
                  </Typography>
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleCloseWrapperAutoatendimento(logout, somenteLeitura)}>
                  <Typography
                    className={classNames(classes.btnMenu, classes.pointer)}
                    color="textPrimary"
                  >
                    <AccountCircleRoundedIcon
                      color="inherit"
                      style={{ marginRight: '5px', fontSize: '2rem' }}
                    />
                    Acessar Autoatendimento
                  </Typography>
                </MenuItem>
              ))) : (
              (!rotaInvalida ? (
                <MenuItem onClick={() => handleCloseWrapper(logout, false)}>
                  <Typography
                    className={classNames(classes.btnMenu, classes.pointer)}
                    color="textPrimary"
                  >
                    <ExitToAppRoundedIcon color="inherit" style={{ marginRight: '5px' }} />
                    Deslogar
                  </Typography>
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleCloseWrapper(logout, somenteLeitura)}>
                  <Typography
                    className={classNames(classes.btnMenu, classes.pointer)}
                    color="textPrimary"
                  >
                    <AccountCircleRoundedIcon
                      color="inherit"
                      style={{ marginRight: '5px', fontSize: '2rem' }}
                    />
                    Entrar
                  </Typography>
                </MenuItem>
              ))
            )}
          </Menu>

          <ModalConfirmacao
            aberto={modal}
            quandoLogar={logout}
            quandoCancelar={handleModal}
          />
        </>
      ) : ''}
    </>
  );
};

export const MenuConta = memo(CardapioMenuConta);
