import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        maxWidth: 1000,
        padding: theme.spacing(1),
        height: '100%',
        position: 'relative'
    }
}))