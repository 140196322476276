
import { MesaModel } from 'api/pedidos/models';
import { validaGuid } from 'utils/valida-guid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';


interface IMesaStore {
    mesa: MesaModel | null;
    empresaId: string | null;
    vincularMesa: (model: MesaModel, empresaId: string) => void;
    desvincularMesa: () => void;
    getMesaId: (pathname: string) => string;
}

export const useMesaStore = create<IMesaStore>()(
    persist(
        (set, get) => ({
            mesa: null,
            empresaId: null,
            vincularMesa(model, empresaId) {
                set((state) => ({
                    ...state,
                    mesa: model,
                    empresaId,
                }))
            },
            desvincularMesa() {
                set((state) => ({
                    ...state,
                    mesa: null,
                }))
            },
            getMesaId(pathname: string) {
                const obj = get();
                if (obj.mesa) {
                    return obj.mesa.id;
                }
                let count = 0;
                const mesaId = pathname.split('/').find(x => {
                    if(validaGuid(x)){
                        if(count > 0){
                            return x;
                        }
                        count++;
                    }
                    return false;
                })
                return mesaId || ''
            },
        }),
        {
            name: 'mesa-model-store',
        }
    )
);  