import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    appBar: ({ capa, pesquisa, categorias, configurando, mostarQrCode }: any) => ({
        transition: 'all 0.3s ease-in-out !important',
        minHeight: 60,
        borderRadius: 'none',
        backgroundPosition: 'top',
        backdropFilter: 'blur(30px)',
        backgroundColor: theme.palette.type === 'dark' ? 'rgb(0 0 0 / .25)' : 'rgb(255 255 255 / .25)',
        backgroundSize: 'cover',
        color: theme.palette.text.primary,
        position: 'fixed',
        top: 0,

        [theme.breakpoints.down('sm')]: {
            backgroundPosition: 'center',
        },
    }),
    conteudoSomenteLeitura: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    btnGarcom: {
        // marginRight: '.3rem',
    },

    btnMenuLogin: {
        paddingLeft: 16,
        display: 'flex',
    },
    logo: {
        height: 50,
        width: 50,
        margin: 0,
        marginRight: '1rem',
        objectFit: 'cover',
        borderRadius: 8,
        backgroundColor: '#fff',
        border: `solid 1px ${theme.palette.type === 'light' ? 'rgba(0,0,0,0.25)' : 'rgba(255,255,255,0.25)'}`
    },
    logoRounded: {
        borderRadius: '50%'
    },
    limitarTitulo: {
        textOverflow: 'ellipsis',
        "-webkit-line-clamp": '1',
        display: '-webkit-box',
        "-webkit-box-orient": 'vertical',
        overflow: 'hidden'
    },
    contentUsuarioLogado: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    carregando: {
        backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
    },
    searchIcon: {
        color: theme.palette.text.primary,
        width: 50,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: 25,
            height: 25, 
        }
    },
    nomeEmpresa: {
        lineHeight: '50px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    containerEmpresa: {
        width: '100%',
    },
    btnAberto: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
        height: '2.5rem'
    },
    btnFechado: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light,
    },
    mesaButton: {
        cursor: 'pointer',
        border: `2px dotted ${theme.palette.divider}`,
        padding: theme.spacing(0, 1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
        width: 130,
        height: 35,
        fontSize: '14px',
        textAlign: 'center',
        '& svg': {
            height: 20,
            width: 20,
            fill: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            fontSize: '12px',
        },
    },
    mesaContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));