export enum EnumInstituicao {
    Nenhuma = -1,
    Itau = 341,
    BANCO_DO_BRASIL = 1,
    Safra = 422
}

export enum EnumTipoComunicacao {
    Nenhuma = -1,
    Boleto = 0,
    Pix = 1,
    Bolix = 2,
    Transferencia = 98,
    Dinheiro = 99
}

export enum EnumAmbientePagamento {
    Sandbox = 0,
    Homologacao = 1,
    Producao = 2
}
export enum EnumPagTpTransacao {
    NORMAL = 0,
    TEF = 1,
    FIDELIDADE = 2,
    MERCADO_PAGO = 3,
    S2_PAY = 4,
    VALE = 5,
    MAMBA = 6,
    PICPAY = 7,
    GRYN_CASH = 8,
    MY_CREDIT = 9,
    MERCADO_PAGO_PIX = 10,
    PAGSEGURO_PIX = 11,
    PENSEBANK_PIX = 14,
    PAYFACE = 15,
    CRMBONUS = 16,
    SAFRA_PIX = 17,
    BOLETO = 18,
    SAURUSPOS = 400,
    ITI = 800,
    SAFRAPAY_WALLET = 10000,
}

export class FinalizadoraModel {
    constructor(
        public id: string = '',
        public empresaId: string = '',
        public descricao: string = '',
        public resumido: string = '',
        public tpTransacao: EnumPagTpTransacao = EnumPagTpTransacao.NORMAL,
        public tpMod: number = 0,
        public tpPagamento: number = 0,
        public vDesc: number = 0,
        public vAcresc: number = 0,
        public pDesc: number = 0,
        public pAcresc: number = 0,
        public vMinParc: number = 0,
        public qMaxParc: number = 0,
        public vencimento: number = 0,
        public qDias: number = 0,
        public ativo: boolean = true,
        public cnpjCred: string = '',
        public credenciais: string = '',
        public credenciado: boolean = true,
        public dataCredenciamento: string = '',
    ) { }
}

export class CredenciamentoModel {
    constructor(
        public instituicao: EnumInstituicao = EnumInstituicao.Safra,
        public tipo: EnumTipoComunicacao = EnumTipoComunicacao.Pix,
        public razaoSocial: string = '',
        public cnpj: string = '',
        public merchantId: string = '',
        public merchantToken: string = '',
        public codigoAtivacao: string = '',
        public chave: string = '',
        public ambiente: EnumAmbientePagamento = EnumAmbientePagamento.Producao,
    ) { }
}
