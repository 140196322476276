import React from "react"
import { Box, Button, Divider, Fade, Grid, Typography, useTheme } from "@material-ui/core";
import { useStyles } from './selecionar-pagamento-styles'
import { Carregando } from "componentes";
import { PixIcon } from "componentes/icons/pix-icon";
import { FormaPagamentoIcon } from "componentes/icons/forma-pagamento-icon";
import { ErroIcon } from "componentes/icons/erro-icon";
import { TentarNovamenteIcon } from "componentes/icons/tentar-novamente-icon";
import { EnumOpcoesDelivery, useObterSacola } from "storage";
import { toDecimalString } from "utils/to-decimal";
import { MaquininhaIcon } from "utils";
import { CartaoIcon } from "componentes/icons/cartao-icon";
import { SelecionarPagamentoProps } from "./selecionar-pagamento-props";
import { FasesHeader } from "../../../components/fases-header/fases-header";
import { EnumPagTpMod } from "api/cardapio/models/pedido/efetuar-pedido";
import { EnumPagTpTransacao } from "api/cardapio/models/finalizadora/finalizadora-model";

export const SelecionarPagamento = ({
    cardapioDados,
    empresaId,
    voltar,
    clickPix,
    clickFinalizar,
    dadosDelivery,
    mesaId,
    handleCancelarPedido,
    clickCredito,
    carregandoRegistrarPix,
    falha,
    msgErro,
}: SelecionarPagamentoProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const itens = useObterSacola(empresaId);
    const produtoEntrega = cardapioDados?.configuracoesDelivery?.produtoEntrega
    const prodEntregaId = produtoEntrega?.id
    const itensSacola = (itens?.data ?? []).filter(x => x.indFin)
    const itensSacolaProduto = itensSacola.filter(x => x.produtoId !== prodEntregaId)
    const itemSacolaEntrega = itensSacola.filter(x => x.produtoId === prodEntregaId)
    const valorItemEntrega = itemSacolaEntrega.reduce((a, b) => a + b.vProd, 0)
    const valorItensSacola = itensSacolaProduto.reduce((a, b) => a + b.vProd, 0)
    const valorTotal = itensSacola.reduce((a, b) => a + b.vProd, 0)
    const finalizadorasCredenciadas = (cardapioDados?.finalizadoras ?? []).filter(x => x.credenciado)
    const hasFinalizadoraCredito = finalizadorasCredenciadas.some(fin => fin.tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET)
    const hasFinalizadoraPix = finalizadorasCredenciadas.some(fin => fin.tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO)

    return (
        <Box height={"100%"} width={"100%"} className={classes.boxContainer}>
            {/* <div className={classes.header}>
                {!(falha || carregandoRegistrarPix) && (
                    <IconButton
                        onClick={voltar}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <ArrowBackIosIcon
                            style={{ paddingLeft: '7px', fontSize: '32px', color: colorSacola }}
                        />
                    </IconButton>
                )}
                <label
                    htmlFor="NomeNoPedido"
                    style={{
                        fontWeight: 700,
                        fontSize: "24px",
                        fontStyle: "revert",
                        color: colorSacola
                    }}
                >
                    PAGAMENTO
                </label>

            </div>
            <Divider style={{ marginTop: '1rem' }} /> */}
            <FasesHeader titulo="PAGAMENTO" voltar={voltar} />
            <div className={classes.scrollable} style={{
                height: '100%',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {carregandoRegistrarPix
                    ? (
                        <Fade in>
                            <div>
                                <Carregando
                                    carregando
                                    titulo="Aguarde..."
                                    mensagem={"Estamos gerando o pagamento PIX."}
                                />
                            </div>
                        </Fade>
                    )
                    : falha ? (
                        <Fade in>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box textAlign={"center"}>
                                        <Typography>
                                            Ops. Ocorreu um problema ao iniciar o pagamento pelo PIX. Gostaria de tentar novamente?
                                        </Typography>
                                        {msgErro && (
                                            <Typography>
                                                Detalhe: {msgErro}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        size="large"
                                        onClick={clickPix}
                                        className={classes.espacamento}
                                    >
                                        <TentarNovamenteIcon tipo="BUTTON_PRIMARY" />
                                        Tentar novamente
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        className={classes.espacamento}
                                        onClick={clickFinalizar}
                                    >
                                        <MaquininhaIcon tipo="BUTTON_PRIMARY" />
                                        {dadosDelivery && dadosDelivery.tipo !== EnumOpcoesDelivery.Retirada ? "Na entrega" : 'Ao chegar'} (Maquininha)
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        size="large"
                                        variant="outlined"
                                        color={
                                            theme.palette.type === "light"
                                                ? "primary"
                                                : "inherit"
                                        }
                                        fullWidth
                                        onClick={handleCancelarPedido}
                                    >
                                        <ErroIcon tipo={theme.palette.type === "light" ? 'BUTTON' : 'BUTTON_PRIMARY'} />
                                        Cancelar pedido
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fade>
                    ) : (
                        <Fade in >
                            <Box display="flex" flexDirection="column" width='100%' height='100%' flex={1}>
                                <Box
                                    className={classes.contentTitle}
                                    flex="1 1 auto"
                                    textAlign="center"
                                    display='flex'
                                    flexDirection="column"
                                    justifyContent="center"
                                >
                                    <Box>
                                        <FormaPagamentoIcon
                                            tipo={
                                                theme.palette.type === "light"
                                                    ? 'BUTTON'
                                                    : 'BUTTON_PRIMARY'
                                            }
                                            style={{
                                                height: 50,
                                                width: 50
                                            }} />
                                    </Box>
                                    <Box mb={3}>
                                        <Typography variant="h4" style={{
                                            fontWeight: 700
                                        }}>
                                            Pagamento
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" style={{ marginBottom: theme.spacing(3) }}>
                                        {finalizadorasCredenciadas.length > 0 ? (
                                            "Você pode escolher entre pagar antecipadamente ou, se preferir, na maquininha no momento da entrega."
                                        ) : (
                                            "O pagamento do pedido será realizado no momento da entrega com maquininha de cartão ou no dinheiro. Para continuar continuar, confirme no botão abaixo"
                                        )
                                        }
                                    </Typography>
                                </Box>
                                <Box p={1}>
                                    <Grid container spacing={2} className={classes.content}>
                                        <Grid item xs={12}>
                                            <Box width="100%" display="flex" flexDirection="column" mb={2}>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography variant="body2">
                                                        Valor Subtotal
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        R$ {toDecimalString(valorItensSacola, 2)}
                                                    </Typography>
                                                </Box>
                                                {!mesaId && (!dadosDelivery || dadosDelivery?.tipo !== EnumOpcoesDelivery.Retirada) && (
                                                    <Box display="flex" justifyContent="space-between">
                                                        <Typography variant="body2">
                                                            Taxa de Entrega
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            R$ {toDecimalString(valorItemEntrega, 2)}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                <Box py={1}>
                                                    <Divider />
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography variant="body2">
                                                        <Box fontWeight={500}>
                                                            Valor Total
                                                        </Box>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <Box fontWeight={500}>
                                                            R$ {toDecimalString(valorTotal, 2)}
                                                        </Box>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        {hasFinalizadoraPix && (
                                            <Grid item xs={12}>
                                                <Button
                                                    size="large"
                                                    onClick={clickPix}
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.espacamento}
                                                    fullWidth
                                                >
                                                    <PixIcon tipo="BUTTON_PRIMARY" />
                                                    Pagar via PIX
                                                </Button>
                                            </Grid>
                                        )}
                                        {hasFinalizadoraCredito && (
                                            <Grid item xs={12}>
                                                <Button
                                                    size="large"
                                                    onClick={clickCredito}
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.espacamento}
                                                    fullWidth
                                                >
                                                    <CartaoIcon tipo="BUTTON_PRIMARY" />
                                                    Pagar via Crédito
                                                </Button>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Button
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                color={theme.palette.type === "light" ? "primary" : "inherit"}
                                                onClick={clickFinalizar}
                                                className={classes.espacamento}
                                            >
                                                <MaquininhaIcon tipo={theme.palette.type === "light" ? 'BUTTON' : 'BUTTON_PRIMARY'} />
                                                {dadosDelivery && dadosDelivery.tipo !== EnumOpcoesDelivery.Retirada ? "Na entrega" : 'Ao chegar'} (Maquininha)
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Fade >
                    )}
            </div>
        </Box>
    )
}