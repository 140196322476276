import { useHistory } from "react-router-dom";
import { validaGuid } from "./valida-guid"

export const useSearchURL = () => {
    const history = useHistory();

    const getIDFromUrl = (type: 'mesaId' | 'empresaId' = 'empresaId') => {
        switch (type) {
            case 'empresaId':
                return history.location.pathname.split('/').find(x => validaGuid(x)) || '';
            default:
                let checks = 0;
                return history.location.pathname.split('/').find(x => {
                    if (validaGuid(x)) {
                        if (checks > 0) {
                            return true;
                        }
                        checks++;
                    }
                    return false;
                }) || ''
        }
    }

    return {
        getIDFromUrl
    }
}