import React, { useCallback, useEffect, useState } from "react";
import { useStyles } from "./payment-checkout-styles";
import CardCheckout from "react-credit-cards-2";
import { Box, Button, Grid } from "@material-ui/core";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { TextFieldSaurus } from "componentes/inputs/text-field-saurus";
import { DataCardFormModel, PaymentCheckoutProps } from "./payment-checkout-props";
import { useCheckout } from "hooks/use-checkout";
import classNames from "classnames";
import { useThemeQueries } from "modulos/cardapio/componentes/produtos/dialog-adicionar-produto-com-subitem/util-styles";
import { CustomerModel, EnumAcquireType, EnumDocumentType, EnumEntityType, EnumGenderType, EnumPhoneType, PhoneModel } from "api/checkout/models";
import { stringNumeros } from "utils/string-numeros";
import { EnumTipoContato } from "api/cardapio/models/enums/enum-tipo-contato";
import { EnumSexo } from "api/cardapio/models/pessoa/pessoa-model";
import { isEmpty } from "lodash";
import { AmbienteEnv } from "ambiente";

export const PaymentCheckout = ({
  responsePayment,
  paymentType,
  amount,
  merchantCredenciais,
  cliente,
  setLoading,
  ...props
}: PaymentCheckoutProps) => {
  // STATES AND REFS
  const [data, setData] = useState<DataCardFormModel>({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    document: "",
    focus: undefined,
  });

  // HOOKS
  const {
    getBrand,
    createTemporaryCard,
    authorizeChargeOnCard,
    setCredentials
  } = useCheckout();

  const isDev = AmbienteEnv === 'devWeb'

  // AUX
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const handleCredentials = useCallback((isUndefined?: boolean) => {
    try {
      setCredentials(isUndefined ? undefined : merchantCredenciais)
    } catch (err: any) {
      console.log(err.message)
    }
  }, [merchantCredenciais, setCredentials])

  useEffect(() => {
    handleCredentials()

    return () => {
      handleCredentials(true)
    }
  }, [handleCredentials, setCredentials])

  useEffect(() => {
    const expiryValid = document.getElementsByClassName("rccs__expiry__valid");
    const expiryValue = document.getElementsByClassName("rccs__expiry__value");
    expiryValid[0].innerHTML = "";
    expiryValue[0].innerHTML = "MM/AA";
  }, []);

  const handleInputChange = (evt: any) => {
    const { name, value } = evt.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt: any) => {
    setData((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const loading = useCallback((value: boolean) => {
    if (setLoading) setLoading(value)
  }, [setLoading])

  const generoMock = useCallback((genero: EnumSexo = EnumSexo.NaoInformado) => ({
    [EnumSexo.Feminino]: EnumGenderType.Female,
    [EnumSexo.Masculino]: EnumGenderType.Male,
    [EnumSexo.NaoInformado]: EnumGenderType.None,
  }[genero]), []);

  const checkout = useCallback(async () => {
    try {
      loading(true)
      const brand = await getBrand(data.number);
      const temporaryCard = await createTemporaryCard(brand, data);

      const cpfCnpj = stringNumeros(cliente.cpfcnpj)
      const contatos = cliente.contatos
      const telefone = stringNumeros(contatos
        .find((contato) => contato.tipo === EnumTipoContato.TELEFONE)
        ?.valor ?? '')
      const email = contatos
        .find((contato) => contato.tipo === EnumTipoContato.EMAIL)
        ?.valor ?? ''
      const document = cpfCnpj || data.document

      const telefoneModel: PhoneModel | undefined =
        isEmpty(telefone)
          ? undefined
          : {
            number: telefone.substring(2, 11) ?? "",
            areaCode: telefone.substring(0, 2) ?? "",
            countryCode: "55",
            type: EnumPhoneType.Mobile,
          } as PhoneModel

      const customer: CustomerModel = {
        address: undefined, // PREENCHER CASO FOR DELIVERY OU SE O USUÁRIO TIVER ENDEREÇO???
        document: document,
        documentType: document.length === 14 ? EnumDocumentType.Cnpj : EnumDocumentType.Cpf,
        email: email || undefined,
        entityType: document.length === 14 ? EnumEntityType.Company : EnumEntityType.IndividualMerchant,
        gender: generoMock(cliente?.sexo),
        name: cliente?.nome || '',
        phone: telefoneModel,
      }

      const payment = await authorizeChargeOnCard({
        cardToken: temporaryCard,
        amount: amount,
        cliente: customer,
        merchantToken: merchantCredenciais.merchantToken,
        merchantCredential: merchantCredenciais.merchantCredential,
        acquirer: isDev ? EnumAcquireType.Simulador : EnumAcquireType.Safrapay,
        softDescriptor: 'saurus'
      });
      const response = payment

      responsePayment({
        data: response,
        messageError: '',
        success: true
      })
      setCredentials(undefined)
    } catch (err: any) {
      responsePayment({
        data: undefined,
        messageError: err.message,
        success: false
      })
    } finally {
      loading(false)
    }
  }, [amount, authorizeChargeOnCard, cliente.contatos, cliente.cpfcnpj, cliente?.nome, cliente?.sexo, createTemporaryCard, data, generoMock, getBrand, isDev, loading, merchantCredenciais.merchantCredential, merchantCredenciais.merchantToken, responsePayment, setCredentials]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    checkout();
  };

  return (
    <Box padding={1} width="100%" height="100%" display={"flex"} alignItems={"center"}>
      <Grid
        container
        spacing={1}
        style={{
          height: "100%",
        }}
      >
        <Grid item xs={12} container justify="center" alignItems="center">
          <Box display={"block"}>
            <Grid container spacing={1} className={classNames(classes.containerCard)}>
              <Grid item xs={12} md={12} className={classes.contentCard}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextFieldSaurus
                        name="number"
                        label="Número do Cartão"
                        value={data.number}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        tipo="CARTAO"
                        fullWidth
                        maxTexto={19}
                        variant="outlined"
                        allowSubmit
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldSaurus
                        name="expiry"
                        label="Validade"
                        value={data.expiry}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        fullWidth
                        tipo="MMAA"
                        maxTexto={5}
                        variant="outlined"
                        allowSubmit
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldSaurus
                        name="cvc"
                        placeholder="CVV"
                        value={data.cvc}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        fullWidth
                        variant="outlined"
                        tipo="NUMERO"
                        maxTexto={3}
                        allowSubmit
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldSaurus
                        name="name"
                        placeholder="Nome"
                        tipo="UPPERCASE"
                        value={data.name}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        fullWidth
                        variant="outlined"
                        allowSubmit
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldSaurus
                        name="document"
                        label="CPF/CNPJ do titular"
                        tipo="CNPJ_CPF"
                        value={data.document}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        fullWidth
                        variant="outlined"
                        allowSubmit
                      />
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        fullWidth
                        style={{ marginTop: theme.spacing(3) }}
                        color="primary"
                      >
                        Finalizar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className={`${classes.contentCard}`}
              >
                <Box
                  height={"100%"}
                  width="100%"
                  display={"flex"}
                  alignItems={"center"}
                  className={classes.stylesCard}
                >
                  <CardCheckout
                    number={data.number}
                    expiry={data.expiry}
                    cvc={data.cvc}
                    name={data.name}
                    focused={data.focus}
                    placeholders={{
                      name: "Nome impresso do cartão",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

