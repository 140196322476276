import React from "react"
import { DialogSaurus } from "../produtos/dialog-adicionar-produto-com-subitem/dialog-saurus/dialog-saurus"
import { Box } from "@material-ui/core";

export interface DialogAutenticarSenhaProps {
    aberto: boolean,
    callback: (senha: string) => any;
}

export const DialogAutenticarSenha = ({
    aberto,
    callback,
}: DialogAutenticarSenhaProps) => {


    // const validarSenha = (senha: string) => {
    //     const senhaConfig = getRegistro(CardapioStorageKeys.DadosGerais);
    // }

    return (
        <DialogSaurus
            aberto={aberto}
            titulo='Autenticação de Administrador'
            isButtonTitleClose

        >
            <Box>
            </Box>
        </DialogSaurus>
    )
}