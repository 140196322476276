import { VariaveisAmbiente } from 'ambiente';
import { AuthError, NotificationError } from 'erros';
import { SaurusError } from 'erros/saurus-error';

export const FetchCardapioApi = async (path: RequestInfo, options?: RequestInit, urlApi?: string) => {
  return await fetch(`${urlApi ? urlApi : VariaveisAmbiente.cardapioApi}${path}`, options).then(
    async (response) => {
      if (response.status === 401)
        throw new AuthError();

      const json = await response
        .clone()
        .json()
        .catch(() => null);

      if (response.ok === false) {
        //ERRO DE RETORNO DE API
        if (response.status >= 400 && response.status <= 499)
          throw new SaurusError((response.status ? ('(' + response.status + ') - ') : '') +
            (json.title || 'Verifique se você realizou as ações corretamente e tente novamente em alguns instantes.'),
            response);
        //ERRO DE FALTA DE COMUNICACAO
        else
          throw new NotificationError('error',
            (response.status ? ('(' + response.status + ') - ') : '') +
            (json.title || 'Erro de comunicação com o servidor, tente novamente em alguns instantes.')
          );
      }

      return response;
    },
  ).catch((e) => {
    if (e instanceof SaurusError || e instanceof SaurusError) {
      throw e;
    }
    if (e instanceof AuthError) {
      throw e;
    }

    throw new NotificationError('error',
      'Erro de comunicação com o servidor. Verifique sua internet e tente novamente em alguns instantes',
      'API Indisponível',
      e?.message + '\nPath: ' + path
    );
  })
};
