import { MergeDeep, timeStorage } from 'utils';
import { FetchCardapioApi } from './fetch-cardapio-api';
import { replaceAll } from 'utils/replace-all';

export const FetchCardapioApiAsMasterUser = (
  path: RequestInfo,
  options?: RequestInit,
): Promise<Response> => {

  const vToken = timeStorage.load('tokenMasterUsuario');

  const Default: RequestInit = {
    headers: {
      Authorization: `Bearer ${replaceAll(vToken, `"`, '')}`,
      'Domain': 'app.touchone.com.br',
    },
  };
  return FetchCardapioApi(path, MergeDeep(Default, options));
};

export const FetchCardapioApiAsMasterUserJSON = <Tipo = any>(
  path: RequestInfo,
  options?: RequestInit,
) => {
  return FetchCardapioApiAsMasterUser(path, options).then(
    (response) => response.json() as Promise<Tipo>,
  );
};
