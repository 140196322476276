import React from 'react';
import { useTheme, useMediaQuery, Typography, Button, Box } from '@material-ui/core';
import { MeusPedidosDesktopIcon } from 'utils/Icons/meus-pedidos-icon-desktop';
import { MeusPedidosIcon } from 'utils';
import { useStyles } from './button-resumo-pedido-styles';

export interface ButtonResumoPedidoProps {
    empresaId: string;
    corLogo: string;
    colorBadge: 'primary' | 'secondary';
    quandoClicado?: () => any;
    size?: string;
    mesaId: string;
}

export const ButtonResumoPedido = ({
    empresaId,
    quandoClicado,
    corLogo,
    colorBadge,
    size,
    mesaId
}: ButtonResumoPedidoProps) => {
    const tema = useTheme();
    const isMobile = useMediaQuery(tema.breakpoints.down('sm'));
    const classes = useStyles();
    return (
        <>
            {isMobile ? (
                <Box style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: 50,
                    width: 50,
                }}>
                    <Box
                        className={classes.containerMeusPedidos}
                        onClick={quandoClicado}
                    >
                        <MeusPedidosIcon tipo='BUTTON_MEU_PEDIDO' />
                    </Box>
                </Box>
            ) : (
                <Box style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button
                        className={classes.containerMeusPedidos}
                        onClick={quandoClicado}
                        variant="text"
                        size="small"
                        fullWidth
                        startIcon={<MeusPedidosDesktopIcon tipo='BUTTON_MEU_PEDIDO' />}
                    >
                        <Typography className={classes.textos}>
                            {`Meus Pedidos`}
                        </Typography>
                    </Button>
                </Box>
            )}
        </>
    );
};
