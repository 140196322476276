import React, { useEffect, useState } from 'react';
import { timeStorage } from 'utils';
import { Carregando } from 'componentes/carregando';
import { useUsuarioLogado, UsuarioProvider } from 'componentes/providers/usuario-provider';
import { Route } from 'componentes/route';
import { Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { CapturarErros, ConfirmProvider, EmpresaTema, useEmpresaDados } from 'componentes';
import { VariaveisAmbiente } from 'ambiente';
import { CardapioEdicao, CardapioConfiguracoes, CardapioHome, } from 'modulos/cardapio/paginas';
import { CardapioMesa } from 'modulos/cardapio/paginas/mesa';
import { CardapioQrCode } from 'modulos/cardapio/paginas/qr-code';
import { PedidosProvider } from 'componentes/providers/pedidos-provider';
import { useTokenUsuario } from 'api/master/queries/token/obter-token-usuario';
import { AtualizarTemaProvider } from 'componentes/providers/atualizar-configuracoes-tema-provider';
import { CardapioStorageKeys, useCardapioStorage } from 'storage/cardapio-storage';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { useEsvaziarSacola } from 'storage';
import { useMesaStore } from 'storage/mesa-store';

export const LoginRoute = () => {
  const { TokenValido } = useTokenUsuario()
  const configuracoesRoute: string = '/configuracoes';
  const editarRoute: string = '/editar';
  const geraQrCodeRoute: string = '/gerar-qrcode';
  const { usuario } = useUsuarioLogado();
  const usuarioValido = usuario?.invalido() === false;
  const { atualizarEmpresaDados } = useEmpresaDados();
  const { empresaId, token } = useParams<any>();
  const { path: routeParams } = useRouteMatch();
  const [esvaziarSacola] = useEsvaziarSacola()
  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(true)
  const { exists, delRegistro } = useCardapioStorage()
  // const { getRegistro, setRegistro } = useCardapioStorage()
  const { location } = useHistory();

  const getMesaId = useMesaStore(state => state.getMesaId)
  const desvincularMesa = useMesaStore(state => state.desvincularMesa);
  const mesaId = getMesaId(location.pathname);

  const mesaRoute: string = `/${mesaId}/mesa`;

  const [carregandoTitulo, setarTitulo] = useState<string | undefined>(
    usuarioValido ? undefined : 'Verificando seu usuário',
  );
  const [carregandoMsg, setarMsg] = useState<string | undefined>(
    usuarioValido ? undefined : 'Aguarde enquanto verificamos seu usuário!',
  );
  const carregando = carregandoInterno

  let tokenUsuario = timeStorage.load("tokenMasterUsuario")
  // SE O CARA MANDAR O TOKEN EU QUERO VALIDAR O ACESSO, SE ESTIVER OK QUERO REDIRECIONAR PARA O CARDAPIO DELE  
  //CARREGAMENTO DO COMPONENTE
  useEffect(() => {
    const sacola = JSON.parse(localStorage.getItem('Sacola')!) as MovSimplesProdutoModel[];
    if (sacola) {
      const hasProdEmpresaDiferente = sacola.filter(x => x.empresaId !== empresaId).length > 0
      if (hasProdEmpresaDiferente) {
        esvaziarSacola({ empresaId })
        localStorage.removeItem('Sacola');
      }
    }
    if (!mesaId) {
      if (exists(CardapioStorageKeys.MesaAtual, false)) {
        delRegistro(CardapioStorageKeys.MesaAtual, false)
        esvaziarSacola({ empresaId })
        localStorage.removeItem('Sacola');
      }
    }
    if (token) {
      if (TokenValido(token)) {
        desvincularMesa();
        timeStorage.save("tokenMasterUsuario", token, 3600)
        // localStorage.setItem(tokenUsuario, JSON.stringify(token))
      }
      window.location.assign(`/${empresaId}`);
      return
    }
    setarTitulo('Carregando Cardápio...');
    setarMsg('Por favor aguarde um momento enquanto localizamos o cardápio do estabelecimento.');

    setTimeout(() => {
      setCarregandoInterno(false)
    }, 500)


    sessionStorage.setItem('ultimoEmpresaId', empresaId!);
  }, [TokenValido, atualizarEmpresaDados, delRegistro, desvincularMesa, empresaId, esvaziarSacola, exists, mesaId, token]);

  if (carregando) {
    return (
      <Carregando
        titulo={carregandoTitulo}
        mensagem={carregandoMsg}
        modelo="fixed"
        carregando
      />
    )
  }

  return (
    <ConfirmProvider>
      <CapturarErros>
        <Switch>
          {!carregando && (
            <EmpresaTema>
              <AtualizarTemaProvider>
                <UsuarioProvider>
                  <PedidosProvider>
                    <Switch>
                      <Route
                        exact
                        path={`${routeParams}`}
                        titulo={`${VariaveisAmbiente.appName} - Home`}
                        component={CardapioHome}
                      />

                      <Route
                        exact
                        path={`${routeParams}${mesaRoute}`}
                        titulo={`${VariaveisAmbiente.appName} - Mesa`}
                        component={CardapioMesa}
                      />

                      <Route
                        exact
                        path={`${routeParams}${editarRoute}`}
                        titulo={`${VariaveisAmbiente.appName} - Edição`}
                        component={CardapioEdicao}
                      />

                      <Route
                        exact
                        path={`${routeParams}${geraQrCodeRoute}`}
                        titulo={`${VariaveisAmbiente.appName} - QrCode`}
                        component={CardapioQrCode}
                      />

                      {tokenUsuario ? (
                        <Route
                          exact
                          path={`${routeParams}${configuracoesRoute}`}
                          titulo={`${VariaveisAmbiente.appName} - Configurações`}
                          component={CardapioConfiguracoes}
                        />
                      ) : (
                        <Redirect to={`/${empresaId}`} />

                      )}

                    </Switch>
                  </PedidosProvider>
                </UsuarioProvider>
              </AtualizarTemaProvider>
            </EmpresaTema>
          )}
          <Redirect to={`/${empresaId}`} />
        </Switch>
      </CapturarErros>
    </ConfirmProvider >
  );
};

