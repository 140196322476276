import React, { useCallback, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  useTheme,
  Button,
  FormLabel,
  useMediaQuery,
  TextField,
  Switch,
  Box,
} from '@material-ui/core';
import { ColorPicker, InputComMascara, useEmpresaTema, useNotificacoes } from 'componentes';
import { getIn, useFormik } from 'formik';
import { EditarTemaFormValidation } from './editar-configuracoes-form-validation';
import { EditarTemaFormProps } from './editar-configuracoes-form-props';
import { useStyles } from './editar-configuracoes-form-styles';
import { EditarDadosInstancia } from '../editar-dados';
import classNames from 'classnames';
import { useAtualizarDadosConfig } from 'componentes/providers/atualizar-configuracoes-tema-provider';
import { isEmpty } from 'lodash';
import { EnumTpAmb, ModEnum } from 'api/cardapio/models/tema/tema-put-model';
import { MesaIcon } from 'utils/Icons/mesa-icon';
import { useMesaStore } from 'storage/mesa-store';
import { EnumDialogs, useDialogStore } from 'storage/dialog-store';
import { VariaveisAmbiente } from 'ambiente';
import { EnumDeviceType } from 'ambiente/ambiente-tipos';

const opcoes = [
  {
    value: false,
    label: 'Não',
  },
  {
    value: true,
    label: 'Sim',
  },
];

const opcaoTipoVenda = [
  {
    value: ModEnum.VendaSimples,
    label: 'Venda Simples',
  },
  {
    value: ModEnum.NFCe,
    label: 'NFC-e',
  },
  {
    value: ModEnum.SAT,
    label: 'SAT',
  },
]

const opcaoAmbiente = [
  {
    value: EnumTpAmb.Homologacao,
    label: 'Homologação',
  },
  {
    value: EnumTpAmb.Producao,
    label: 'Produção',
  },
]

const distanciaInicial = [
  {
    value: 10,
    label: '10 metros',
  },
  {
    value: 20,
    label: '20 metros',
  },
  {
    value: 30,
    label: '30 metros',
  },
  {
    value: 40,
    label: '40 metros',
  },
  {
    value: 50,
    label: '50 metros',
  },
  {
    value: 100,
    label: '100 metros',
  },
  {
    value: 200,
    label: '200 metros',
  },
  {
    value: 300,
    label: '300 metros',
  },
  {
    value: 400,
    label: '400 metros',
  },
  {
    value: 500,
    label: '500 metros',
  },
  {
    value: 900,
    label: '900 metros',
  },
  {
    value: 1000,
    label: '1 km',
  },
  {
    value: 5000,
    label: '5 km',
  },
  {
    value: 50000,
    label: '50 km',
  },
  {
    value: 100000,
    label: '100 km',
  },
];


export const EditarConfiguracaoForm = React.forwardRef<EditarDadosInstancia, EditarTemaFormProps>(
  ({ cardapioDados: empresa, ...props }: EditarTemaFormProps, ref) => {
    // DEPENDENCIAS
    const classes = useStyles();
    const { tema } = useEmpresaTema();
    const { showErrorMessage, showWarningMessage } = useNotificacoes();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // PROVIDER
    const { modelFormatado } = useAtualizarDadosConfig();
    const mesa = useMesaStore((state) => state.mesa);
    const openCloseDialog = useDialogStore((state) => state.openCloseDialog);

    React.useImperativeHandle(ref, () => ({
      submit: triggerSubmit,
    }));

    const finalizadoraCredenciada = empresa?.finalizadoras.filter(x => x.credenciado)

    const {
      errors,
      touched,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      setFieldValue,
      setErrors
    } = useFormik({
      enableReinitialize: true,
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: false,
      validationSchema: EditarTemaFormValidation,
      initialValues: {
        logo: tema?.logo!,
        autoAtendimento: tema?.autoAtendimento!,
        capa: tema?.capa!,
        distanciaMaxima: tema?.distanciaMaxima!,
        informacaoAdicional: tema?.informacaoAdicional!,
        permiteEntrega: tema?.permiteEntrega!,
        permiteRetirada: tema?.permiteRetirada!,
        senhaAcesso: tema?.senhaAcesso!,
        statusLoja: tema?.statusLoja!,
        tipoUsoCardapio: tema?.tipoUsoCardapio!,
        empresaId: tema?.empresaId!,
        type: tema?.type!,
        primaryMain: tema?.primaryMain!,
        primaryLight: tema?.primaryLight!,
        primaryDark: tema?.primaryDark!,
        primaryContrast: tema?.primaryContrast!,
        secondaryMain: tema?.secondaryMain!,
        secondaryLight: tema?.secondaryLight!,
        secondaryDark: tema?.secondaryDark!,
        secondaryContrast: tema?.secondaryContrast!,
        textPrimary: tema?.textPrimary!,
        textSecondary: tema?.textSecondary!,
        obrigaPagamento: true,
        serie: tema?.serie!,
        ambiente: tema?.ambiente!,
        tipoVenda: tema?.tipoVenda!,
        disponibilizarPagamentoFinalizacao: tema?.disponibilizarPagamentoFinalizacao!
      },
      onSubmit: modelFormatado,
    });

    const submitCorTema = useCallback(async () => {
      if (
        isEmpty(values.primaryContrast) ||
        isEmpty(values.primaryDark) ||
        isEmpty(values.primaryLight) ||
        isEmpty(values.primaryMain)
      ) {
        setErrors({
          primaryMain: 'Este campo é obrigatório.'
        })
        showErrorMessage('Estes campos são obrigatórios!');
      } else {
        const cores = theme.palette.augmentColor({
          '500': values.primaryMain,
        });
        if (values.type === 0) {
          values.secondaryMain = cores.light
          values.textPrimary = '#000000'
        } else {
          values.textPrimary = '#FFFFFF'
          values.secondaryMain = cores.dark
        }
        await modelFormatado(values);
      }
    }, [modelFormatado, setErrors, showErrorMessage, theme.palette, values])

    const triggerSubmit = useCallback(() => {
      submitCorTema();
    }, [submitCorTema])

    const redefinirCores = useCallback(() => {
      if (props.redefinir) {
        props.setValueProps((prev: any) => ({
          ...prev!,
          primaryMain: setFieldValue('primaryMain', ''),
          primaryDark: setFieldValue('primaryDark', ''),
          primaryLight: setFieldValue('primaryLight', ''),
          primaryContrast: setFieldValue('primaryContrast', ''),
          secondaryMain: setFieldValue('secondaryMain', ''),
          secondaryDark: setFieldValue('secondaryDark', ''),
          secondaryLight: setFieldValue('secondaryLight', ''),
          secondaryContrast: setFieldValue('secondaryContrast', ''),
          textPrimary: setFieldValue('textPrimary', ''),
          textSecondary: setFieldValue('textSecondary', ''),
          type: setFieldValue('type', 0),
        }));
      }
      props.setRedefinir(false);
    }, [props, setFieldValue])

    useEffect(() => {
      const timeOuutId = setTimeout(() => {
        redefinirCores();
      }, 300)

      return () => {
        clearTimeout(timeOuutId);
      }
    }, [props, redefinirCores, setFieldValue])


    const quandoCorMainForAlterada = useCallback(
      (tipo: string) => (cor?: string) => {

        if (cor === '#' || !cor) {
          setFieldValue(`${tipo}Main`, cor, true);
          return
        }
        const cores = theme.palette.augmentColor({
          '500': cor,
        });

        setFieldValue(`${tipo}Main`, cor, true);
        setFieldValue(`${tipo}Light`, cores.light, true);
        setFieldValue(`${tipo}Dark`, cores.dark, true);
        setFieldValue(`${tipo}Contrast`, cores.contrastText, true);
        setFieldValue(`secondaryMain`, cores.light, true);
        setFieldValue(`secondaryLight`, cores.light, true);
        setFieldValue(`secondaryDark`, cores.dark, true);
        setFieldValue(`secondaryContrast`, cores.contrastText, true);
      },
      [setFieldValue, theme],
    );

    const retornaEnderecoEmpresa = () => {
      if ((empresa?.empresa?.logradouro ?? "") === "")
        return "";

      return (empresa?.empresa?.logradouro ?? "") + ", " +
        (empresa?.empresa?.numero ?? "") + " - " +
        (empresa?.empresa?.complemento ?? "")
    }

    const redirectModoVizualizacao = async () => {
      if (!values.autoAtendimento) {
        showWarningMessage('Para você entrar como administrador, o campo de autoatendimento precisa estar ativado!');
      }
      else {
        await modelFormatado(values);
      }
    }

    return (
      <>
        <Paper className={classes.form} elevation={0} component="form" onSubmit={handleSubmit as any}>
          <Typography
            variant="h6"
            className={classNames(classes.typographyMarginTop, classes.typographyMarginBottom)}
          >
            <strong>Configurações do Cardápio</strong>
            <Divider />
          </Typography>

          <Grid spacing={1} container>
            <Grid item xs={12} sm={12} md={8}>
              <TextField
                label="Endereço Atual"
                placeholder="Endereço não Cadastrado"
                name="enderecoEmpresa"
                type={'text'}
                disabled
                value={retornaEnderecoEmpresa()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                id="select-distancia-maxima"
                select
                name="distaciaMaxima"
                label="Perímetro de Atendimento Presencial"
                value={values.distanciaMaxima}
                onChange={(event) => {
                  if (event) {
                    setFieldValue('distanciaMaxima', event.target.value, true);
                  }
                }}
                helperText=""
              >
                {distanciaInicial.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="select-permite-entrega"
                select
                name="permiteEntrega"
                label="Permite Entrega"
                value={values.permiteEntrega}
                onChange={(event) => {
                  if (event) {
                    setFieldValue('permiteEntrega', event.target.value, true);
                  }
                }}
                helperText=""
              >
                {opcoes.map((option) => (
                  <MenuItem key={option.value as any} value={option.value as any}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="select-permite-retirada"
                select
                name="permiteRetirada"
                label="Permite Retirada"
                value={values.permiteRetirada}
                onChange={(event) => {
                  if (event) {
                    setFieldValue('permiteRetirada', event.target.value, true);
                  }
                }}
                helperText=""
              >
                {opcoes.map((option) => (
                  <MenuItem key={option.value as any} value={option.value as any}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="disponibilizarPagamentoFinalizacao"
                select
                name="disponibilizarPagamentoFinalizacao"
                label="Disponibilizar pagamento na finalização"
                value={values.disponibilizarPagamentoFinalizacao}
                disabled={(finalizadoraCredenciada ?? []).length === 0}
                onChange={(event) => {
                  if (event) {
                    setFieldValue('disponibilizarPagamentoFinalizacao', event.target.value, true);
                  }
                }}
                helperText=""
              >
                {opcoes.map((option) => (
                  <MenuItem key={option.value as any} value={option.value as any}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {(finalizadoraCredenciada ?? []).length === 0 && (
                <Typography variant="caption" color="textSecondary">
                  Você precisa credenciar a forma de pagamento PIX para disponibizar.
                </Typography>
              )}
            </Grid>
            {false && (
              <Grid item xs={12} sm={4}>
                <InputComMascara
                  label={'Telefone WhatsApp'}
                  name="telefoneWhatsapp"
                  placeholder="DDD + Telefone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mascara="(99) 9999-99999"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={5} md={4}>
              <FormControl component="fieldset" >
                <FormLabel component="legend">Habilitar Autoatendimento</FormLabel>
                <Switch
                  checked={Boolean(values.autoAtendimento)}
                  color="primary"
                  onChange={() => {
                    setFieldValue('autoAtendimento', !Boolean(values.autoAtendimento), true);
                  }}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </FormControl>
            </Grid>
            {Boolean(values.autoAtendimento) && (
              <>
                <Grid item xs={12} sm={isMobile ? 12 : 3} md={3} style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Button
                    fullWidth={true}
                    color="secondary"
                    onClick={redirectModoVizualizacao}
                  >
                    Acessar Administrador
                  </Button>
                </Grid>
                <Grid item xs={12} sm={isMobile ? 12 : 7} md={5}>
                  <TextField
                    label="Senha de Supervisor para Autoatendimento"
                    placeholder="Senha de Supervisor para Autoatendimento"
                    name="senhaAcesso"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={'password'}
                    value={values.senhaAcesso}
                    error={Boolean(
                      touched.senhaAcesso &&
                      errors.senhaAcesso,
                    )}
                    helperText={
                      touched.senhaAcesso &&
                        errors.senhaAcesso
                        ? errors.senhaAcesso
                        : undefined
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>

          {values.disponibilizarPagamentoFinalizacao && (
            <>
              <Typography
                variant="h6"
                className={classNames(classes.typographyMarginTop, classes.typographyMarginBottom)}
              >
                <strong>Configurações para venda</strong>
                <Divider />
              </Typography>

              <Grid spacing={1} container>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Série"
                    name="serie"
                    type={'text'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.serie}
                    error={Boolean(
                      errors.serie,
                    )}
                    helperText={
                      errors.serie
                        ? errors.serie
                        : "Número do PDV + 100"
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="tipoVenda"
                    select
                    name="tipoVenda"
                    label="Tipo de Venda"
                    value={values.tipoVenda}
                    onChange={(event) => {
                      if (event) {
                        setFieldValue('tipoVenda', event.target.value, true);
                      }
                    }}
                    error={Boolean(
                      getIn(touched, 'tipoVenda') &&
                      getIn(errors, 'tipoVenda'),
                    )}
                    helperText={
                      getIn(touched, 'tipoVenda') &&
                        getIn(errors, 'tipoVenda')
                        ? getIn(errors, 'tipoVenda')
                        : undefined
                    }
                  >
                    {opcaoTipoVenda.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="ambiente"
                    select
                    name="ambiente"
                    label="Ambiente"
                    value={values.ambiente}
                    onChange={(event) => {
                      if (event) {
                        setFieldValue('ambiente', event.target.value, true);
                      }
                    }}
                    helperText=""
                  >
                    {opcaoAmbiente.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        <Box className={classes.flexContainer}>
          <Paper
            className={classNames(props.className, classes.form, classes.tema, VariaveisAmbiente.deviceType === EnumDeviceType.NAVIGATOR ? classes.fullWidth : '')}
            elevation={0}
            component="form"
            onSubmit={handleSubmit as any}
          >
            <Typography variant="h6" className={classes.marginBottom}>
              <strong>Edição do tema</strong>
              <Divider />
            </Typography>
            <Typography component="p" variant="caption" gutterBottom className={classes.descricao}>
              O Cardapio é composto por uma cor Principal{' '}
              <strong className={classes.primary}>Primária</strong> e ao resto iremos adaptar os textos e fundo conforme o tema e cor que escolher.
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  error={Boolean(errors.type && touched.type)}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                >
                  <InputLabel>Selecionar Modo</InputLabel>
                  <Select
                    name="type"
                    label="Selecionar Modo"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="0">Light (Claro)</MenuItem>
                    <MenuItem value="1">Dark (Escuro)</MenuItem>
                  </Select>
                  {errors.type && touched.type && <FormHelperText>{errors.type}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ColorPicker
                  name="primaryMain"
                  label="Primária"
                  value={values.primaryMain}
                  onChange={quandoCorMainForAlterada('primary')}
                  onBlur={handleBlur('primaryMain')}
                  error={Boolean(errors.primaryMain)}
                  helperText={
                    errors.primaryMain ? errors.primaryMain : undefined
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          {VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA && <Paper
            className={classNames(props.className, classes.form, classes.vincularMesa)}
            elevation={0}
            component="form"
            onSubmit={handleSubmit as any}
          >
            <Typography variant="h6" className={classes.marginBottom}>
              <strong>Vincular Mesa ao Dispositivo</strong>
              <Divider />
            </Typography>
            <Typography component="p" variant="caption" gutterBottom className={classes.descricao}>
              Vincule uma mesa fixa para funcionar sempre com este dispositivo.
            </Typography>
            <Box className={classes.cardMesa} onClick={() => {
              openCloseDialog(EnumDialogs.SelecionarMesa, true);
            }}>
              <MesaIcon tipo='GERAL' />
              <Typography>
                {mesa ? `Mesa ${mesa.codigo}` : 'Nenhuma Mesa Fixa Vínculada'}
              </Typography>
            </Box>
          </Paper>}
        </Box>
      </>
    );
  },
);
