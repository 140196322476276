import React, { useEffect, useMemo, useRef, useState } from "react"
import { DialogSaurus } from "../produtos/dialog-adicionar-produto-com-subitem/dialog-saurus/dialog-saurus";
import { EnumDialogs, useDialogStore } from "storage/dialog-store";
import { Box, Button } from "@material-ui/core";
import { useStyles } from './dialog-selecionar-mesa-styles'
import { FormSelecionarMesa, FormSelecionarMesaRefs } from "./components/form-selecionar-mesa";
import { useMesaStore } from "storage/mesa-store";
import { CloseIcon } from "../produtos/dialog-adicionar-produto-com-subitem/dialog-saurus/components/icons/close-icon";
import { OkIcon } from "utils/Icons/ok-icon";
import { useNotificacoes } from "componentes";
import { MesaModel, SalaoModel } from "api/pedidos/models";
import { obterSalao } from "api/pedidos/queries";
import { obterMesas } from "api/pedidos/queries/obter-mesas/obter-mesas";
import { useSearchURL } from "utils/get-id-url";

export interface DialogSelecionarMesaProps {
    aberto: boolean;
}

export const DialogSelecionarMesa = ({
    aberto,
}: DialogSelecionarMesaProps) => {

    const { getIDFromUrl } = useSearchURL();

    const empresaId = useMemo(() => {
        return getIDFromUrl('empresaId');
    }, [getIDFromUrl]);

    const [saloes, setSaloes] = useState<SalaoModel[]>([]);
    const [mesas, setMesas] = useState<MesaModel[]>([]);

    const { showSuccessMessage } = useNotificacoes();
    const openCloseDialog = useDialogStore(state => state.openCloseDialog);
    const { mesa, desvincularMesa, vincularMesa } = useMesaStore(state => state);

    const classes = useStyles();
    const formRef = useRef<FormSelecionarMesaRefs>(null);

    const handleSubmit = async (mesaId: string) => {
        const mesa = mesas.find(item => item.id === mesaId);
        if(mesa){
            vincularMesa(mesa, empresaId);
            showSuccessMessage(`Mesa ${mesa.codigo} vinculada à este dispostivo.`);
            openCloseDialog(EnumDialogs.SelecionarMesa, false);
        }
    }

    const clickDesvincular = () => {
        const numeroMesa = mesa?.codigo;
        desvincularMesa();
        showSuccessMessage(`Mesa ${numeroMesa} desvinculada deste dispostivo.`);
        openCloseDialog(EnumDialogs.SelecionarMesa, false);
    }

    useEffect(() => {
        (async () => {
          setSaloes(await obterSalao.execute(empresaId))  
          setMesas(await obterMesas.execute(empresaId))
        })()
    }, [empresaId])

    useEffect(() => {
        if(mesa && saloes.length > 0 && mesas.length > 0){
            const salaoSelecionado = saloes.find(x => x.id === mesa.salaoId);
            const mesaSelecionada = mesas.find(x => x.id === mesa.id);

            if(!mesaSelecionada || !salaoSelecionado) return;

            formRef.current?.fillForm(mesaSelecionada.id, salaoSelecionado.id);
        }
    }, [mesa, mesas, saloes])

    return (
        <DialogSaurus
            aberto={aberto}
            titulo='Selecionar Mesa'
            isButtonTitleClose
            tamanho='sm'
            handleClickClose={() => {
                openCloseDialog(EnumDialogs.SelecionarMesa, false)
            }}
            bottomArea={<>
                <Box p={2} display='flex' gridGap={16} justifyContent='flex-end' width='100%'>
                    {mesa && <Button className={classes.buttonDesvincular} onClick={() => {
                        clickDesvincular();
                    }}>
                        <CloseIcon tipo='BUTTON_PRIMARY' />
                        Desvincular do Dispostivo
                    </Button>}
                    <Button onClick={() => formRef.current?.submit()}>
                        <OkIcon tipo='BUTTON_PRIMARY' />
                        Vincular Mesa
                    </Button>
                </Box>
            </>}
        >
            <Box className={classes.container}>
                <FormSelecionarMesa
                    listaMesas={mesas}
                    listaSaloes={saloes}
                    onSubmit={handleSubmit}
                    ref={formRef}
                />
            </Box>
        </DialogSaurus>
    )
}