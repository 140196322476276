import { Cardapio } from "modulos/cardapio/containers";
import { LocalizacaoUsuario } from "modulos/cardapio/containers/cardapio/cardapio-localizacao"
import React from "react"
import { useLocation, useParams } from "react-router-dom"
import { useMesaStore } from "storage/mesa-store";

export const CardapioMesa = () => {
    const { empresaId } = useParams<{ empresaId: string }>();
    const { pathname } = useLocation();
  
    const getMesaId = useMesaStore(state => state.getMesaId)
    const mesaId = getMesaId(pathname);

    return (
        <>
            <LocalizacaoUsuario empresaId={empresaId} mesaId={mesaId}>
                <Cardapio somenteLeitura={true} configurando={false} contextoSelecionado="visualizacao" />
            </LocalizacaoUsuario>
        </>
    );
}