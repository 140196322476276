import { VariaveisAmbiente } from "ambiente";
import { EnumDeviceType } from "ambiente/ambiente-tipos";

export const GetAuthCallbackUrl = (rota: string) => {
  const urlAtual = new URL(window.location.href);
  const novaUrlCallback = new URL(urlAtual.origin + '/api/v4/autenticar/');
  novaUrlCallback.searchParams.set('token', '@token');
  if (rota.length > 0) {
    novaUrlCallback.searchParams.set('rota', rota);
  }
  return btoa(novaUrlCallback.href.replace(/%40/gi, '@'));
};
export const GetAuthADMRedirect = (empresaId: string) => {
  let url = 'https://app.touchone.com.br';
  if (VariaveisAmbiente.isDev) {
    url = 'https://app-dev.touchone.com.br'
  }
  return `${url}/login?callbackUrl=${btoa(`${window.location.origin}/{empresaId}/login/{accessToken}`)}&empresaId=${empresaId}`;
}

export const GetAuthLoginRedirect = () => {
  let url = 'https://app.touchone.com.br';
  let urlCallback = window.location.origin;
  if (VariaveisAmbiente.isDev) {
    url = 'https://app-dev.touchone.com.br'
  }
  if(VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA){
    urlCallback = `cardapiotouchone://cardapio.touchone.com.br`
  }
  return `${url}/login?callbackUrl=${btoa(`${urlCallback}/{empresaId}/login/{accessToken}`)}`;
}
