import React, { useCallback, useRef, useState } from "react"
import { EnumFasesSacola } from "../../../cardapio-sacola";
import { Box, Slide } from "@material-ui/core";
import { usePedidos } from "componentes/providers/pedidos-provider";
import { useNotificacoes } from "componentes";
import { DadosDelivery } from "storage";
import { SelecionarPagamento } from "./components/selecionar-pagamento/selecionar-pagamento";
import { InformarCartao } from "./components/informar-cartao/informar-cartao";
import { CardapioStorageKeys, useCardapioStorage } from "storage/cardapio-storage";
import { CardapioDadosProps } from "modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props";
import { FinalizadoraModel } from "api/cardapio/models/finalizadora/finalizadora-model";

export interface FormasPagamentoProps {
    faseSacola: EnumFasesSacola
    entrarFases: (fase: EnumFasesSacola) => void
    fecharSacola: () => void
    empresaId: string
    cardapioDados: CardapioDadosProps | undefined
    mesaId: string
    dadosDelivery: DadosDelivery | undefined
}

export enum TpFormaPagamentoEnum {
    Entrega,
    PIX,
    Cartao
}

export enum EnumFalhaFormaPag {
    Nenhuma,
    Pix,
    Entrega
}

export enum EnumFasePagamento {
    SelecionarPagamento,
    InformarCartao
}

export const FormasPagamento = ({
    entrarFases,
    faseSacola,
    fecharSacola,
    empresaId,
    cardapioDados,
    mesaId,
    dadosDelivery,
}: FormasPagamentoProps) => {
    const nextOrBack = useRef<0 | 1>(1)

    const faseFormas = faseSacola === EnumFasesSacola.FormaPagamento

    const { showSuccessMessage } = useNotificacoes()
    const [falha, setFalha] = useState<boolean>(false)
    const [msgErro, setMsgErro] = useState<string>('')
    const [fasesPagamento, setFasesPagamento] = useState<EnumFasePagamento>(EnumFasePagamento.SelecionarPagamento)
    const { getRegistro } = useCardapioStorage()
    const dadosGerais = getRegistro(CardapioStorageKeys.DadosGerais, false) as CardapioDadosProps;
    const [finalizadoraAtual, setFinalizadoraAtual] = useState<FinalizadoraModel | undefined>(undefined)

    const {
        carregandoRegistrarPix,
        handleRegistroPix,
        cancelarPedido
    } = usePedidos()

    const proximo = useCallback((fase: EnumFasesSacola) => {
        nextOrBack.current = 1

        setTimeout(() => {
            entrarFases(fase)
        }, 50)

        nextOrBack.current = 0
    }, [entrarFases])

    const voltar = useCallback(() => {
        nextOrBack.current = 0

        setTimeout(() => {
            entrarFases(EnumFasesSacola.Sacola)
        }, 50)

        nextOrBack.current = 1
    }, [entrarFases])

    const getFinalizadoraCredito = useCallback(() => {
        const finalizadoras = dadosGerais?.finalizadoras
        const creditoPag = finalizadoras.find(fin => fin.tpMod === 3)
        if (creditoPag?.credenciais) {
            setFinalizadoraAtual(creditoPag)
        }
        return creditoPag
    }, [dadosGerais?.finalizadoras])

    const clickPix = useCallback(async () => {
        try {
            setMsgErro('')
            setFalha(false)
            await handleRegistroPix()
            proximo(EnumFasesSacola.PagamentoPix)
        } catch (err: any) {
            setMsgErro(err.message)
            setFalha(true)
        }
    }, [handleRegistroPix, proximo])

    const clickCredito = useCallback(() => {
        try {
            setMsgErro('')
            setFalha(false)
            getFinalizadoraCredito()
            setFasesPagamento(EnumFasePagamento.InformarCartao)
        } catch (err: any) {
            setMsgErro(err.message)
            setFalha(true)
        }
    }, [getFinalizadoraCredito])

    const handleCancelarPedido = useCallback(() => {
        cancelarPedido()
        showSuccessMessage('Pedido cancelado!')
        fecharSacola()
    }, [cancelarPedido, fecharSacola, showSuccessMessage])

    const voltarSelecao = () => {
        setFasesPagamento(EnumFasePagamento.SelecionarPagamento)
    }

    const clickFinalizar = () => {
        proximo(EnumFasesSacola.FazendoPedido)
    }

    return (
        <Slide direction={nextOrBack.current === 1 ? "left" : 'right'} mountOnEnter unmountOnExit in={faseFormas}>
            <Box height="100%">
                {fasesPagamento === EnumFasePagamento.SelecionarPagamento && (
                    <SelecionarPagamento {...{
                        carregandoRegistrarPix,
                        clickCredito,
                        clickFinalizar,
                        clickPix,
                        dadosDelivery,
                        cardapioDados,
                        empresaId,
                        falha,
                        handleCancelarPedido,
                        mesaId,
                        msgErro,
                        voltar,
                    }} />
                )}
                {fasesPagamento === EnumFasePagamento.InformarCartao && (
                    <InformarCartao
                        clickFinalizar={clickFinalizar}
                        entrarFases={entrarFases}
                        fecharSacola={fecharSacola}
                        voltar={voltarSelecao}
                        empresaId={empresaId}
                        finalizadoraAtual={finalizadoraAtual}
                    />
                )}
            </Box>
        </Slide>
    )
}